import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'va-edit-input-date',
  template: `
    @if (!isEditing) {
      <span class="value-content">
        <div [ngStyle]="{ order: defaultOrder ? 1 : 2 }" class="value">
          {{ value | localizedDate }}
        </div>
        <div [ngStyle]="{ order: defaultOrder ? 2 : 1 }" class="icon">
          <button
            pButton
            pRipple
            type="button"
            [pTooltip]="placeholder | translate"
            [tooltipPosition]="'top'"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-text p-button-plain icon-edit"
            (click)="onClickEdit()"
          ></button>
        </div>
      </span>
    }
    @if (isEditing) {
      <div class="p-inputgroup">
        <p-calendar
          [style]="{ width: '100%' }"
          [appendTo]="'body'"
          [placeholder]="placeholder | translate"
          [selectionMode]="'single'"
          [dateFormat]="'dd/MM/yyyy'"
          [readonlyInput]="false"
          [showTime]="true"
          [hourFormat]="'24'"
          [ngModel]="value"
          (ngModelChange)="onValueChange($event)"
          name="value"
        ></p-calendar>
        @if (value !== prevValue) {
          <span class="p-inputgroup-addon">
            <i class="pi pi-save icon-pointer" (click)="onClickSave()"></i>
          </span>
        }
        <span class="p-inputgroup-addon">
          <i class="pi pi-times icon-pointer" (click)="onClickCancel()"></i>
        </span>
      </div>
    }
    `,
})
export class EditInputDateComponent implements OnInit {
  @Input() placeholder: string;
  @Input() style: any | string;
  @Input() value: Date;
  @Input() defaultOrder = true;
  @Input() isEditing = false;
  @Output()
  isEditingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickSave: EventEmitter<any> = new EventEmitter<any>();
  prevValue: Date;

  constructor() {
    this.prevValue = this.value;
  }

  ngOnInit(): void {
    this.prevValue = this.value;
  }

  onClickEdit(): void {
    this.prevValue = this.value;
    this.isEditingChange.emit(true);
  }

  onValueChange(event: any): void {
    this.prevValue = event;
    this.valueChange.emit(event);
  }

  onClickSave(): void {
    this.prevValue = this.value;
    this.isEditingChange.emit(true);
    this.clickSave.emit();
  }

  onClickCancel(): void {
    this.prevValue = this.value;
    this.isEditingChange.emit(false);
  }
}
