export enum TypeInputEnum {
  SLIDER = 'slider',
  DROPDOWN = 'dropdown',
  MULTISELECTION = 'multiselection',
  DATE = 'date',
  TEXT = 'text',
  TEXT_AREA = 'textarea',
  NUMBER = 'number',
  RADIOBUTTON = 'radiobutton',
  CHECKBOX = 'checkbox',
}
