<va-input-layout
  [filterItem]="filterItem"
  [operatorsType]="'number'"
  (operatorChange)="operatorChange($event)"
  (typeChange)="typeChange($event)"
  (resetInput)="resetInput()"
  (restoreInput)="restoreInput($event)"
>
  <ng-container input>
    <div style="width: 100%">
      <p-calendar
        [appendTo]="'body'"
        [style]="filterItem.style"
        [placeholder]="filterItem.placeholder | translate"
        [selectionMode]="type | lowercase"
        [dateFormat]="filterItem.optionsDate.dateFormat"
        [dataType]="filterItem.optionsDate.dateType"
        [readonlyInput]="filterItem.optionsDate.readonlyInput"
        [showTime]="filterItem.optionsDate.showTime"
        [hourFormat]="filterItem.optionsDate.hourFormat"
        [ngModel]="value"
        (ngModelChange)="setHourValue($event)"
        name="value"
      ></p-calendar>
      <input [ngModel]="filterItem.key" hidden="true" name="field" />
      <input [ngModel]="operator" hidden="true" name="operator" />
      <input [ngModel]="filterItem.logic" hidden="true" name="logic" />
      <input [ngModel]="filterItem.typeInput" hidden="true" name="type" />
      <input [ngModel]="filterItem.formatValue" hidden="true" name="formatValue" />
    </div>
  </ng-container>
</va-input-layout>
