<i class="pi pi-cog icon-pointer" (click)="panel.toggle($event)"></i>
<p-overlayPanel #panel [showCloseIcon]="true" [style]="overlayStyle">
  <ul class="item-ot">
    @for (type of types; track type) {
      <li>
        <p-radioButton
          class="desc"
          [inputId]="type.key"
          [name]="type"
          [value]="type"
          [label]="type.label | translate"
          [(ngModel)]="selected"
          (click)="typeChange.emit(type)"
        ></p-radioButton>
      </li>
    }
  </ul>
</p-overlayPanel>
