import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { UserService } from '@core/services/user.service';

@Injectable({ providedIn: 'root' })
export class LoggedGuard implements CanActivate, CanLoad {
  token: string;
  appId: string;

  constructor(private router: Router, private userService: UserService) {
    this.appId = this.userService.getAppId();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if (this.toHaveAccess) {
      this.router.navigate(['tenant', this.appId, 'assets']);

      return false;
    }

    return true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.toHaveAccess) {
      this.router.navigate(['tenant', this.appId, 'assets']);

      return false;
    }

    return true;
  }

  private get toHaveAccess(): boolean {
    let isLoggedIn;

    this.userService.isLoggedIn$.subscribe((res) => {
      isLoggedIn = res;
    });

    return isLoggedIn;
  }
}
