import { Injectable } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { SplashAnimationType } from '@shared/enum/splash-animation-type.enum';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  private animationDuration$: BehaviorSubject<number> = new BehaviorSubject<number>(0.5);
  private duration$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  private animationType$: BehaviorSubject<SplashAnimationType> = new BehaviorSubject<SplashAnimationType>(
    SplashAnimationType.FadeOut,
  );

  constructor(private configService: ConfigService) {
    this.configService.getConfig().subscribe((res) => {
      this.setAnimationDuration(res.splashScreen.animationDuration);
      this.setDuration(res.splashScreen.duration);
      this.setAnimationType(res.splashScreen.animationType);
    });
  }

  setAnimationDuration(animationDuration: number): void {
    this.animationDuration$.next(animationDuration);
  }

  getAnimationDuration(): Observable<number> {
    return this.animationDuration$.asObservable();
  }

  setDuration(duration: number): void {
    this.duration$.next(duration);
  }

  getDuration(): Observable<number> {
    return this.duration$.asObservable();
  }

  setAnimationType(animationType: SplashAnimationType): void {
    this.animationType$.next(animationType);
  }

  getAnimationType(): Observable<SplashAnimationType> {
    return this.animationType$.asObservable();
  }
}
