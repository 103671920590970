import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'va-spinner',
  template: `<div class="spinner">
    <p-progressSpinner
      [style]="{ width: '150px', height: '150px' }"
      styleClass="spinner"
      strokeWidth="8"
      fill="#047dff"
      animationDuration=".5s"
    ></p-progressSpinner>
    <div class="spinner-logo"></div>
  </div>`,
})
export class SpinnerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
