import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApplicationInsightsService {
  appInsights: ApplicationInsights;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    const appInsightsKey = environment.appinsight_instrumentationkey;

    if (!appInsightsKey) {
      console.error('Application Insights key not found.');
      return;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsKey,
        enableAutoRouteTracking: true,
      },
    });

    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.createRouterSubscription();
  }

  // helper methods to track a variety of events and metric
  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      var item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties['ApplicationPlatform'] = 'Web';
      item.properties['ApplicationName'] = 'vaimoo-fe';
    });
  }

  private createRouterSubscription() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((page: NavigationEnd) => {
        const lastChild = (child) => (child.firstChild ? lastChild(child.firstChild) : child);
        this.logPageView(lastChild(this.activatedRoute.firstChild).snapshot.data.title, page.url);
      });
  }
}
