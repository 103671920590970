import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SystemService } from '@codegen/Vaimoo.Admin.API';
import { environment } from 'src/environments/environment';
import { MenuItem } from '../models/menu-item.model';
import { UserOverlayComponent } from './user-overlay.component';

@Component({
  selector: 'va-sidebar',
  template: `
    <div class="sidebar">
      <a href="/" class="sidebar-logo" [pTooltip]="version" [tooltipPosition]="'right'"></a>
      @if (menuItems && menuItems.length > 0) {
        <ul class="sidebar-menu">
          @for (menu of menuItems; track menu; let i = $index) {
            <li
              [class.sidebar-items]="!menu?.IsSpacer"
              [class.spacer]="menu?.IsSpacer"
              (click)="menu.IsUser ? userOverlay.userDetails.toggle($event) : ''"
              >
              <a
                #menu
                class="sidebar-link"
                #firstLevel="routerLinkActive"
                [routerLink]="menu.RouterLink"
                [routerLinkActive]="'active-sidebar'"
                [disableLink]="menu?.IsNode"
                [pTooltip]="menu.Label | translate"
                [tooltipPosition]="'right'"
                (click)="menuOnChange.emit(menu)"
                >
                <i class="va-icon" [ngClass]="menu?.Icon" [class.va-active]="firstLevel.isActive"></i>
                @if (menu?.IsUser) {
                  <div style="padding: 7px">
                    <va-user-thumbs
                      [firstname]="userInfo?.firstName"
                      [lastname]="userInfo?.lastName"
                    ></va-user-thumbs>
                    <va-user-overlay #userOverlay [userInfo]="userInfo"></va-user-overlay>
                  </div>
                }
              </a>
            </li>
          }
        </ul>
      }
    </div>
    `,
})
export class SidebarComponent implements OnInit {
  @ViewChild('userOverlay') userOverlay: UserOverlayComponent;

  @Input() menuItems: MenuItem[];
  @Input() userInfo: any;
  @Output() menuOnChange: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

  version = '';

  constructor(private systemService: SystemService) {
    this.systemService.pingGet().subscribe((res) => {
      this.version = `FE: v${environment.buildNumber} (${environment.release}) | BE: v${res.version}`;
    });
  }

  ngOnInit(): void {
    console.log();
  }
}
