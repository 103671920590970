import { Component, Input, OnInit } from '@angular/core';
import { ColumnPanel } from '@shared/interfaces/column-panel.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'va-fieldset-table',
  template: ` <fieldset [class.panel-fieldset]="legend" [class.panel-fieldset-no-border]="!legend">
      @if (legend) {
        <legend class="panel-legend">
          {{ legend | translate }}
        </legend>
      }
      <ul class="panel-table">
        @if (value && columns && columns.length > 0) {
          @for (col of columns; track col) {
            @if (col.visible) {
              <li class="list-panel-table">
                <span
                  [class]="col.tooltip ? 'spacer pi pi-info-circle color-yellow' : 'spacer'"
                  [pTooltip]="col.tooltip | translate"
                  [tooltipPosition]="'top'"
                ></span>
                <label>
                  {{ col.header | translate }}
                </label>
                @if (!col.customCellTemplate) {
                  {{ col.value(getField(value, col.field)) | optionValuePipe }}
                } @else {
                  <ng-template
                *ngTemplateOutlet="
                  col.customCellTemplate;
                  context: {
                    $implicit: col.value(getField(value, col.field)) | optionValuePipe
                  }
                "
                  >
                </ng-template>
              }
              <ng-template #customCellTemplate>
                <ng-template
                *ngTemplateOutlet="
                  col.customCellTemplate;
                  context: {
                    $implicit: col.value(getField(value, col.field)) | optionValuePipe
                  }
                "
                >
              </ng-template>
            </ng-template>
          </li>
        }
      }
    } @else {
      @if (loading | async) {
        @for (s of skeleton; track s) {
          <li class="list-panel-table-skeleton">
            <p-skeleton width="100%" height="15px"></p-skeleton>
            <p-skeleton width="100%" height="15px"></p-skeleton>
          </li>
        }
      }
    }
    <ng-template #s>
      @if (loading | async) {
        @for (s of skeleton; track s) {
          <li class="list-panel-table-skeleton">
            <p-skeleton width="100%" height="15px"></p-skeleton>
            <p-skeleton width="100%" height="15px"></p-skeleton>
          </li>
        }
      }
    </ng-template>
    @if (!value && !(loading | async)) {
      <li>
        <div class="panel-empty-message">{{ emptyMessage | translate }}</div>
      </li>
    }
    </ul>
    </fieldset>`,
})
export class FieldsetTableComponent implements OnInit {
  @Input() legend: string;
  @Input() columns: ColumnPanel[];
  @Input() value: any;
  @Input() loading: Observable<boolean>;
  @Input() emptyMessage = 'COMMONS.PANEL.EMPTY_MESSAGE';

  skeleton = new Array(10);

  ngOnInit(): void {}

  getField(value: any | any[], field: string): any | any[] {
    const fields = field.split('.');
    if (fields.length > 1 && value[fields[0]]) {
      return this.getField(value[fields[0]], fields.slice(1).join('.'));
    }

    return value[field];
  }
}
