import { FractionPipe } from './fraction.pipe';
import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
  name: 'percentagePipe',
})
export class PercentagePipe implements PipeTransform {
  constructor(
    private optionalValuePipe: OptionalValuePipe,
    private percentPipe: PercentPipe,
    private fractionPipe: FractionPipe,
  ) {}

  transform(value: any, divider: number): any {
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    if (!divider) {
      divider = 1;
    }

    if (value == 0) {
      return this.percentPipe.transform(value);
    }

    let val = this.fractionPipe.transform(value, divider);

    return this.percentPipe.transform(val);
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
