import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FilterModel } from '@shared/interfaces/filter-model.interface';
import { FilterBase } from '@shared/interfaces/filter-base.interface';
import { EnumConverterPipe } from '@shared/pipes/enum-converter.pipe';
import { LocalizedDatePipe } from '@shared/pipes/localized-date.pipe';
import { FiltersService } from '@shared/services/filters.service';
import { QueryParamsService } from '@shared/services/query-params.service';
import { FiltersUtil } from '@shared/utils/filters.util';
import moment from 'moment';

@Component({
  selector: 'va-filters',
  template: `<div class="filter-container">
        <div class="filter-selected">
          @for (s of filtersSelected; track s; let i = $index) {
            <span
              class="filter-selected-span"
              (mouseenter)="s.isOpen = true"
              (mouseleave)="s.isOpen = false"
              >
              @if (!s.filters) {
                <span class="filter-badge-label">
                  <span class="field">{{ label(s) | uppercase }}</span>
                  @if (s?.formatValue) {
                      <span class="value">
                        {{ value(s) | dynamicPipe : s?.formatValue }}
                      </span>
                  }
                  @if (!s.formatValue) {
                    <span class="value">
                      {{ value(s) }}
                    </span>
                  }
                  <span class="filter-badge-op">
                    {{ operator(s) }}
                  </span>
                </span>
              }
              @if (s.isOpen) {
                <va-button
                  [class]="'va-button-filter-item'"
                  [type]="'only-icon'"
                  [icon]="'fa fa-times'"
                  (clickButton)="deleteFilter(i)"
                ></va-button>
              }
            </span>
          }
        </div>
        <div class="filter-button">
          @if (filtersSelected.length === 0) {
            <va-button
              [style]="{ float: 'right' }"
              [label]="'COMMONS.FILTERS.CREATE_FILTERS' | translate"
              (clickButton)="showDialogFilters()"
            ></va-button>
          }
          @if (filtersSelected.length > 0) {
            <va-button
              [type]="'only-icon'"
              [icon]="'fa fa-edit'"
              (clickButton)="showDialogFilters()"
            ></va-button>
          }
          @if (filtersSelected.length > 0) {
            <va-button
              [style]="{ float: 'right' }"
              [type]="'only-icon'"
              [icon]="'fa fa-times'"
              (clickButton)="deleteFilters()"
            ></va-button>
          }
        </div>
      </div>
      <va-filter-dialog
        [(show)]="showModal"
        [filters]="filters"
        (filtersResult)="getFiltersResult($event)"
      ></va-filter-dialog>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() filters: FilterBase[] = [];
  @Input() filtersName: string;
  @Input() width = 500;
  @Input() height = 550;
  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();

  isOpen = false;
  filtersSelected: FilterModel[] = [];
  filtersData: FilterModel[] = [];
  showModal = false;
  formValues: any;
  subscription: Subscription;

  constructor(
    private queryParamsService: QueryParamsService,
    private translateService: TranslateService,
    private enumConverterPipe: EnumConverterPipe,
    private filterService: FiltersService,
    private localizedDatePipe: LocalizedDatePipe,
  ) {
    this.subscription = this.filterService.getDefaultFilters().subscribe((filters) => {
      if (filters) {
        this.filtersSelected = JSON.parse(filters).filters;
        this.filtersData = FiltersUtil.SplitRangeValue(this.filtersSelected);
        this.queryParamsService.setQueryParamsEncoded('filters', this.filtersSelected);
      }
    });
  }

  ngOnInit(): void {
    this.filtersSelected = this.queryParamsService.getQueryParamsDecoded('filters');
    this.filtersData = FiltersUtil.SplitRangeValue(this.filtersSelected);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  label(filter: FilterModel): string {
    let label = this.translateService.instant(
      `COMMONS.FILTERS.LABELS.${this.filtersName}.${filter.field
        .replace(/[A-Z]/g, (letter: any) => `_${letter.toUpperCase()}`)
        .toUpperCase()}`,
    );

    return `${label}:`;
  }

  value(filter: FilterModel): string {
    let val = Array.isArray(filter.value)
      ? this.checkArrayOfValue(filter.type, filter.value)
      : this.checkSingleValue(filter.type, filter.value);

    return `${val}`;
  }

  operator(filter: FilterModel): string {
    let operator = filter.operator;
    if (Array.isArray(operator)) {
      return operator.map((el: string) => this.enumConverterPipe.transform(el, 'OPERATORS')).join(' - ');
    }

    return this.enumConverterPipe.transform(operator, 'OPERATORS');
  }

  getFiltersResult(filters: any): void {
    let filtersArray: any[] = Object.entries(filters)
      .reduce((acc, [key, value]) => {
        acc.push(value);
        return acc;
      }, [] as any[])
      .filter((el) => el.type == 'date');
    filtersArray.forEach((el) => {
      if (Array.isArray(el.value) && el.value.length && el.value[0] && !el.value[1]) {
        let startDate = moment(el.value[0]).set({ second: 0 });
        let endDate = moment(el.value[0]).set({ second: 59 });

        el.value = [startDate, endDate];
      } else if (Array.isArray(el.value) && el.value.length && el.value[0] && el.value[1]) {
        let startDate = moment(el.value[0]).set({ second: 0 });
        let endDate = moment(el.value[1]).set({ second: 59 });

        el.value = [startDate, endDate];
      }
    });
    if (filters) {
      let value = filters;
      this.filtersSelected = FiltersUtil.ObjectToArray(value);
      this.filtersData = FiltersUtil.SplitRangeValue(value);
      if (this.filtersSelected.length > 0) {
        this.filterChange.emit(this.filtersData);
        this.queryParamsService.setQueryParamsEncoded('filters', this.filtersSelected);
      }
    }
  }

  showDialogFilters(): void {
    this.showModal = true;
  }

  deleteFilter(index: number): void {
    this.filtersData = this.filtersData.filter((el) => el.field != this.filtersSelected[index].field);

    this.filterService.setResetFilters({
      type: 'resetSingle',
      reset: true,
      id: this.filtersSelected[index].field,
    });

    this.filtersSelected.splice(index, 1);
    this.filterChange.emit(this.filtersData);

    this.queryParamsService.setQueryParamsEncoded('filters', this.filtersSelected);
  }

  deleteFilters(): void {
    this.filtersSelected = [];
    this.filterChange.emit([]);
    this.filterService.setResetFilters({ type: 'resetAll', reset: true });
    this.queryParamsService.deleteQueryParams();
  }

  private checkArrayOfValue(type: string, value: any[]): any {
    let option: string;

    switch (type) {
      case 'multiselection':
        option = ' , ';
        if (!value[1]) {
          return value[0];
        }
        return value.join(option);
      case 'date':
        option = ' - ';
        if (!value[1]) {
          return this.localizedDatePipe.transform(value[0], false);
        }

        return value
          .map((el) => {
            return this.localizedDatePipe.transform(el, false);
          })
          .join(option);
      default:
        option = ' - ';
        if (!value[1]) {
          return value[0];
        }

        return value.join(option);
    }
  }

  private checkSingleValue(type: string, value: any): any {
    switch (type) {
      case 'date':
        return this.localizedDatePipe.transform(value, false);
      default:
        return value;
    }
  }
}
