import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from '@layout/models/menu-item.model';
import { SidebarService } from '@layout/services/sidebar.service';

@Component({
  selector: 'va-section',
  template: `@if (menuItem && menuItem.Childs.length) {
  <div class="section">
    <div class="section-header">
      <div class="section-title">
        <h4>{{ menuItem.Label | translate }}</h4>
      </div>
      <div class="section-close">
        <va-button-event [icon]="'pi pi-times'" (clickIcon)="closeClick()"></va-button-event>
      </div>
    </div>
    <ul class="section-menu">
      @for (child of menuItem.Childs; track child) {
        <li class="section-item">
          <a
            class="section-link"
            #secondLevel="routerLinkActive"
            [disableLink]="child.IsNode"
            [routerLink]="child.RouterLink"
            [routerLinkActive]="'active-section'"
            (click)="child.IsNode ? (child.IsActive = !child.IsActive) : ''"
            >
            {{ child.Label | translate }}
            @if (secondLevel.isActive && child.Childs.length === 0) {
              <i
                class="pi pi-arrow-right section-icon"
              ></i>
            }
            @if (child.Childs.length > 0) {
              <i
                class="fa right"
            [ngClass]="{
              'fa-angle-up': child.IsActive,
              'fa-angle-down': !child.IsActive
            }"
              ></i>
            }
          </a>
          @if (child.Childs && child.Childs.length > 0 && child.IsActive) {
            <ul>
              @for (subChild of child.Childs; track subChild) {
                <li class="section-item">
                  <a
                    class="section-link"
                    #thirdLevel="routerLinkActive"
                    [disableLink]="subChild.IsNode"
                    [routerLink]="subChild.RouterLink"
                    [routerLinkActive]="'active-section'"
                    (click)="subChild.IsNode ? (subChild.IsActive = !subChild.IsActive) : ''"
                    >
                    {{ subChild.Label | translate }}
                    @if (subChild.IsActive && subChild.Childs.length === 0) {
                      <i
                        class="pi pi-arrow-right section-icon"
                      ></i>
                    }
                    @if (subChild.Childs.length > 0) {
                      <i
                        class="fa right"
                [ngClass]="{
                  'fa-angle-up': subChild.IsActive,
                  'fa-angle-down': !subChild.IsActive
                }"
                      ></i>
                    }
                  </a>
                  @if (subChild.Childs && subChild.Childs.length > 0 && subChild.IsActive) {
                    <ul>
                      @for (subSubChild of subChild.Childs; track subSubChild) {
                        <li class="section-item">
                          <a
                            class="section-link"
                            #fourthLevel="routerLinkActive"
                            [routerLinkActive]="'active-section'"
                            [routerLink]="subSubChild.RouterLink"
                            >
                            {{ subSubChild.Label | translate }}
                            @if (fourthLevel.isActive) {
                              <i class="pi pi-arrow-right section-icon"></i>
                            }
                          </a>
                        </li>
                      }
                    </ul>
                  }
                </li>
              }
            </ul>
          }
        </li>
      }
    </ul>
  </div>
}`,
})
export class SectionComponent implements OnInit {
  private _menuItem: MenuItem;

  @Input() set menuItem(menuItem: MenuItem) {
    this._menuItem = menuItem;
  }

  get menuItem(): MenuItem {
    return this._menuItem;
  }

  constructor(private sidebarService: SidebarService) {}

  ngOnInit(): void {
    console.log();
  }

  closeClick(): void {
    this.sidebarService.toggleSidebar();
  }
}
