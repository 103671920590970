import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnTable } from '@shared/interfaces/column-table.interface';

@Component({
  selector: 'va-column-settings-overlay',
  template: `
    <va-button-event
      [pTooltip]="'COMMONS.COLUMNS.TITLE' | translate"
      [tooltipPosition]="'top'"
      [icon]="'pi pi-cog'"
      (clickIcon)="list.toggle($event)"
    ></va-button-event>
    <p-overlayPanel #list [showCloseIcon]="true" [style]="overlayCellsStyle">
      <ul class="column-settings">
        @for (item of items; track item) {
          <li class="column-item">
            @if (!item.action) {
              <p-checkbox
                class="item"
                [label]="item.header | translate"
                [(ngModel)]="item.visible"
                (ngModelChange)="itemsChange.emit(items)"
                [disabled]="disabled && item.visible"
                binary="true"
                >
              </p-checkbox>
            }
          </li>
        }
      </ul>
    </p-overlayPanel>
    `,
})
export class ColumnSettingsOverlayComponent implements OnInit {
  @Input() class: string;
  @Input() items: ColumnTable[];
  @Output() itemsChange: EventEmitter<ColumnTable[]> = new EventEmitter<ColumnTable[]>();

  overlayCellsStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.75)',
    width: 'auto',
    margin: 0,
  };

  constructor() {}

  ngOnInit(): void {
    console.log();
  }

  get disabled(): boolean {
    let visible = this.items.filter((el) => !el.visible).length;
    let media = this.items.length / 2;

    return visible > media;
  }
}
