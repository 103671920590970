import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { HeaderService } from '@layout/services/header.service';
import { SidebarService } from '@layout/services/sidebar.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'va-header',
  template: `
    <div class="layout-header-container">
      <div class="layout-header">
        @if (true) {
          <div class="menu">
            <va-button-event [icon]="'pi pi-bars toogle'" (clickIcon)="openMenu()"></va-button-event>
          </div>
        }
        <div class="header-left left">
          <h4>
            {{ title | translate }}
          </h4>
          <ng-content select="[header-left]"></ng-content>
        </div>
        <div class="header-right right">
          <ng-content select="[header-right]"></ng-content>
        </div>
      </div>
      <div class="header-footer">
        <ng-content select="[header-footer]"></ng-content>
      </div>
      <va-breadcrumbs [enabled]="enabled"></va-breadcrumbs>
    </div>
    `,
})
export class HeaderComponent implements OnInit, OnDestroy {
  title: string;
  enabled = true;
  subscription: Subscription;

  constructor(
    private headerService: HeaderService,
    private configService: ConfigService,
    private sidebarService: SidebarService,
  ) {
    this.subscription = this.configService.getConfig().subscribe((config) => {
      this.enabled = config.breadcrumbs;
    });
  }

  ngOnInit(): void {
    this.getTitle();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openMenu() {
    this.sidebarService.toggleSidebarMobile();
  }

  private getTitle(): void {
    this.headerService.title.subscribe((updatedTitle) => {
      this.title = updatedTitle;
    });
  }
}
