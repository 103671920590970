import { Injectable, OnDestroy, inject } from '@angular/core';
import { GlobalConfig, StorageKey } from '@core/config/global.config';
import { BehaviorSubject, catchError, Observable, of, Subscription, tap } from 'rxjs';
import { ConfigService } from './config.service';
import { SessionService } from './session.service';
import { AuthService } from '@codegen/Vaimoo.Admin.API';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

function checkRoles(rolesToCheck: string[], rolesOwned: string[]): boolean {
  if (rolesOwned && rolesOwned.length > 0) {
    for (const entry of rolesToCheck) {
      if (rolesOwned.includes(entry)) {
        return true;
      }
    }
  }

  return false;
}

@Injectable()
export class UserService implements OnDestroy {
  isLoggedIn$ = new BehaviorSubject<boolean>(!!this.sessionService.getItem('token'));

  token$: Observable<string>;
  tokenSubject: BehaviorSubject<string>;
  
  refreshToken$ = new BehaviorSubject<boolean>(false);

  subscription: Subscription;
  enableHideMenuItem: boolean;

  constructor(
    private sessionService: SessionService,
    private configService: ConfigService,
    private http: HttpClient
  ) {
    this.getConfig();
  }

  private authService = inject(AuthService);

  checkTokenExpiration(): Observable<boolean> {
    const token = this.sessionService.getItem(StorageKey.token);
    const today = new Date();

    if (!token) {
      this.logout();
      return of(false);
    } else if (today > new Date(this.parseJwt(token).exp * 1000)) {
      this.logout();
      return of(false);
    } else {
      return of(true);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getUser(): any {
    return this.sessionService.getItem(StorageKey.user);
  }

  getAppId(): string {
    return this.sessionService.getItem(StorageKey.appId);
  }

  getToken(): string {
    return this.sessionService.getItem(StorageKey.token);
  }

  getUserRoles(): any[] {
    return this.sessionService.getItem(StorageKey.roles);
  }

  getUserLanguage(): string {
    return this.sessionService.getItem(StorageKey.language);
  }

  checkRoles(rolesToCheck: string[]): boolean {
    return this.enableHideMenuItem ? this.enableHideMenuItem : checkRoles(rolesToCheck, this.getUserRoles());
  }

  login(token: string, refreshToken: string, appId: string, currentUser: any, roles: any[]): void {
    this.sessionService.setItem(StorageKey.token, token);
    this.sessionService.setItem(StorageKey.refreshToken, refreshToken);
    this.sessionService.setItem(StorageKey.appId, appId);
    this.sessionService.setItem(StorageKey.user, currentUser);
    this.sessionService.setItem(StorageKey.roles, roles);
    this.sessionService.setItem(StorageKey.language, GlobalConfig.language.defaultLang);
    this.isLoggedIn$.next(true);
  }

  logout(): void {
    this.sessionService.removeItem(StorageKey.token);
    this.sessionService.removeItem(StorageKey.refreshToken);
    this.sessionService.removeItem(StorageKey.roles);
    this.sessionService.removeItem(StorageKey.user);
    this.sessionService.removeItem(StorageKey.language);
    this.sessionService.removeItem(StorageKey.fleet);
    this.isLoggedIn$.next(false);
  }

  getRefreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      return this.http.post(environment.baseUrlAdminApi+'/auth/refresh-token', "", {
        headers: {
          'RefreshToken': JSON.parse(refreshToken),
          'Authorization': '',
          'Content-Type': 'application/json',
          'Accept': '*/*'
        }
      }).pipe(
        tap((res) => {
          this.sessionService.setItem(StorageKey.token, res['accessToken'].token);
          this.sessionService.setItem(StorageKey.refreshToken, res['accessToken'].refreshToken);
        }),
        catchError(_ => {
          return of(this.logout());
        }),
      );
    }
    return of(this.logout());
  }

  private getConfig(): void {
    this.subscription = this.configService.getConfig().subscribe((res) => {
      this.enableHideMenuItem = res.sidebar.enableHideMenu;
    });
  }

  private parseJwt(token: string) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }
}
