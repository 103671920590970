import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface ResetFilter {
  type: 'restore' | 'resetSingle' | 'resetAll';
  reset: boolean;
  id?: string;
  value?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private resetFilters$: BehaviorSubject<ResetFilter> = new BehaviorSubject<ResetFilter>({
    type: 'resetAll',
    reset: false,
  });
  private advanced$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    localStorage.getItem('advancedFilter') === 'true' || false,
  );
  private defaultFilters$: Subject<string> = new Subject<string>();

  constructor() {}

  setAdvancedFilter(value: boolean): void {
    localStorage.setItem('advancedFilter', JSON.stringify(value.toString()));
    this.advanced$.next(value);
  }

  getAdvancedFilter(): Observable<boolean> {
    return this.advanced$.asObservable();
  }

  setResetFilters(value: ResetFilter): void {
    this.resetFilters$.next(value);
  }

  getResetFilters(): Observable<ResetFilter> {
    return this.resetFilters$.asObservable();
  }

  setDefaultFilters(value: string) {
    this.defaultFilters$.next(value);
  }

  getDefaultFilters(): Observable<string> {
    return this.defaultFilters$.asObservable();
  }
}
