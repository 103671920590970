import { Config } from '@core/interfaces/config.interface';
import { SplashAnimationType } from '@shared/enum/splash-animation-type.enum';
import { ThemeMap } from '@shared/enum/theme-map.enum';

export const GlobalConfig: Config = {
  splashScreen: {
    duration: 1,
    animationDuration: 1,
    animationType: SplashAnimationType.SlideLeft,
  },
  language: {
    multilanguage: false,
    defaultLang: 'en',
  },
  breadcrumbs: false,
  filterAdvanced: true,
  sidebar: {
    enableHideMenu: false,
  },
  map: {
    mapBarEnabled: false,
    mapTheme: ThemeMap.LIGHT_MONOCHROME,
  },
  table: {
    columnSettingsState: false,
    columnSettingsType: 'modal',
  },
};

export const DateConfigFilter = 'YYYY-MM-dd HH:mm';

export const DateConfig = {
  it: 'dd/MM/yyyy HH:mm',
  en: 'MM/dd/yyyy h:mm a',
};

export const ASAP = '01/01/1753 00:49';

export const PipeConfig = {
  temp: { unit: 10, symbol: '°C', format: '1.1-1' },
  kn: { symbol: 'kn', format: '1.1-1' },
  m: { symbol: 'm', format: '1.0' },
  km: { symbol: 'km', format: '1.1-1' },
  kmh: { symbol: 'km/h', format: '1.1-1' },
  mA: { symbol: 'mA', format: '1.0' },
  mAh: { symbol: 'mAh', format: '1.0' },
  mV: { symbol: 'mV', format: '1.0' },
  km_day: { symbol: 'km/day', format: '1.1-1' },
  km_month: { symbol: 'km/month', format: '1.1-1' },
  degrees: { symbol: '°', format: '1.0' },
};

export const DefaultUnit = {
  direction_deegres: 10,
  altitude_meters: 10,
  averageDailyUsage_km_day: 100,
  lastMonthUsage_km_month: 100,
  remainingDistance_km: 100,
  odometer_km: 100,
  speed_kmh: 100,
};

export const SilentApi = ['auth', 'update-repair', 'add-repair', 'validate-visual-id'];

export const UrlsNotUse = ['task/enqueue-ota-updates'];

export const RefreshTokenUrlPattern = '\/auth\/refresh-token$';

export const StorageKey = {
  user: 'currentUser',
  token: 'token',
  refreshToken: 'refreshToken',
  appId: 'appId',
  roles: 'roles',
  language: 'language',
  config: 'config',
  fleet: 'fleet',
};
