import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldBase } from '@shared/models/forms/field-base.model';

@Component({
  selector: 'va-dynamic-input',
  template: `<div [formGroup]="form">
      @if (field.controlType !== 'checkbox') {
        <label [attr.for]="field.key">{{ field.label | translate }}</label>
      }
      @switch (field.controlType) {
        @case ('text') {
          <div>
            <input
              pInputText
              [ngStyle]="field.style"
              [type]="field.type"
              [formControlName]="field.key"
              [placeholder]="field.placeholder | translate"
              [name]="field.key"
              />
            </div>
          }
          @case ('textarea') {
            <div>
              <textarea
                pInputTextarea
                [ngStyle]="field.style"
                [formControlName]="field.key"
                [placeholder]="field.placeholder | translate"
                [name]="field.key"
                [rows]="field['options'].rows"
                [cols]="field['options'].cols"
              ></textarea>
            </div>
          }
          @case ('number') {
            <div>
              <input
                pInputText
                [ngStyle]="field.style"
                [type]="field.type"
                [formControlName]="field.key"
                [placeholder]="field.placeholder | translate"
                [name]="field.key"
                />
              </div>
            }
            @case ('date') {
              <div>
                <p-calendar
                  [style]="field.style"
                  [appendTo]="'body'"
                  [inputStyle]="field.style"
                  [formControlName]="field.key"
                  [placeholder]="field.placeholder | translate"
                  [dateFormat]="field['options'].dateFormat"
                  [dataType]="field['options'].dateType"
                  [readonlyInput]="field['options'].readonlyInput"
                  [showTime]="field['options'].showTime"
                  [hourFormat]="field['options'].hourFormat"
                  [name]="field.key"
                ></p-calendar>
              </div>
            }
            @case ('dropdown') {
              <div>
                <p-dropdown
                  [style]="field.style"
                  [appendTo]="'body'"
                  [options]="field['options'].options"
                  [formControlName]="field.key"
                  [placeholder]="field.placeholder | translate"
                  [showClear]="field['options'].showClear"
                  [filter]="field['options'].filter"
                  [filterBy]="field.value.options.filter"
                  [name]="field.key"
                ></p-dropdown>
              </div>
            }
            @case ('checkbox') {
              <div>
                <div class="checkbox-content">
                  <input
                    class="p-checkbox p-checkbox-box p-checkbox-icon"
                    type="checkbox"
                    [formControl]="form.controls[field.key]"
                    [value]="field.value"
                    [name]="field.value"
                    />
                    <label [attr.for]="field.key">
                      {{ field.label | translate }}
                    </label>
                  </div>
                </div>
              }
            }
          </div>`,
})
export class DynamicInputComponent {
  @Input() field!: FieldBase<string>;
  @Input() form!: FormGroup;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  get isValid(): boolean {
    return this.form.controls[this.field.key].valid;
  }
}
