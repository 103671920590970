import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function ApplicationInitializerFactory(
  injector: Injector,
  translate: TranslateService,
): () => Promise<void> {
  return () =>
    new Promise<any>((resolve: any) => {
      translate.addLangs(['en', 'it']);
      const langLocalStorage = localStorage.getItem('language');
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

      locationInitialized.then(() => {
        const browserLang = translate.getBrowserLang();
        const langToSet = 'en'; //langLocalStorage ? langLocalStorage : browserLang.match(/en|it/) ? browserLang : 'en';
        translate.setDefaultLang(langToSet);
        translate.use(langToSet).subscribe({
          next: () => {
            resolve(null);
          },
          error: (err) => {
            console.error(`Problem with '${langToSet}' language initialization.'`);
          },
        });
      });
    });
}
