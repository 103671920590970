import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInsightsService } from '@core/services/insights.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'va-table-menu',
  template: `
    <span class="p-buttonset" style="display: flex;">
      <button
        class="p-button-secondary"
        pButton
        pRipple
        type="button"
        [label]="label"
        [routerLink]="link"
        (click)="logTrace(link.join('/'))"
      ></button>
      @if (items && items.length > 0) {
        <button
          pButton
          pRipple
          type="button"
          [icon]="icon"
          (click)="menuOverlay.toggle($event)"
        ></button>
      }
      <p-menu
        #menuOverlay
        [appendTo]="'body'"
        [showTransitionOptions]="'0ms'"
        [hideTransitionOptions]="'0ms'"
        [popup]="true"
        [model]="items"
      ></p-menu>
    </span>
    `,
})
export class TableMenuComponent implements OnInit {
  private _items: MenuItem[];

  @Input() icon = 'fa fa-list';
  @Input() label: string;
  @Input() link: string[];
  @Input()
  set items(items: MenuItem[]) {
    this._items = items.filter((el) => el.visible);
  }

  get items(): MenuItem[] {
    return this._items;
  }

  constructor(private applicationInsightsService: ApplicationInsightsService) {}

  ngOnInit(): void {
    console.log();
  }

  logTrace(url: string) {
    this.applicationInsightsService.logTrace('User clicked the button to go to the page: ' + url, {
      routerLink: url,
    });
  }
}
