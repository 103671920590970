import { Injectable, inject } from '@angular/core';
import { AuthService } from '@codegen/Vaimoo.Admin.API';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class SessionService {
  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any {
    var value = localStorage.getItem(key);
    return JSON.parse(String(value));
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
