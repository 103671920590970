<va-input-layout
  [filterItem]="filterItem"
  [operatorsType]="'string'"
  (operatorChange)="operatorChange($event)"
  (typeChange)="typeChange($event)"
  (resetInput)="resetInput()"
  (restoreInput)="restoreInput($event)"
>
  <ng-container input>
    <div style="width: 100%">
      <p-multiSelect
        [style]="filterItem.style"
        [options]="filterItem.optionsMultiselection.options"
        [(ngModel)]="value"
        [defaultLabel]="filterItem.placeholder | translate"
        [showHeader]="false"
        [selectionLimit]="filterItem.optionsMultiselection.selectionLimit"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [display]="filterItem.optionsMultiselection.display"
        name="value"
      ></p-multiSelect>
      <input [ngModel]="filterItem.key" hidden="true" name="field" />
      <input [ngModel]="operator" hidden="true" name="operator" />
      <input [ngModel]="filterItem.logic" hidden="true" name="logic" />
      <input [ngModel]="filterItem.typeInput" hidden="true" name="type" />
      <input [ngModel]="filterItem.formatValue" hidden="true" name="formatValue" />
    </div>
  </ng-container>
</va-input-layout>
