import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationType } from '@core/enums/notification-type.enum';
import { NotificationService } from '@core/services/notification.service';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'va-notification-toast',
  template: `<p-toast></p-toast>`,
  styles: [
    `
      :host ::ng-deep .p-toast {
        position: fixed;
        width: auto !important;
      }
    `,
  ],
})
export class NotificationComponent implements OnInit, OnDestroy {
  subscription?: Subscription;

  constructor(private notificationService: NotificationService, private notification: MessageService) {}

  ngOnInit(): void {
    this.subscription = this.notificationService.listenNotificationToastr().subscribe((notification) => {
      this.openNotification(notification);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  openNotification(notification: Message): void {
    switch (notification.severity) {
      case NotificationType.INFO:
        this.notification.add(notification);
        break;

      case NotificationType.SUCCESS:
        this.notification.add(notification);
        break;

      case NotificationType.WARNING:
        this.notification.add(notification);
        break;

      case NotificationType.ERROR:
        this.notification.add(notification);
        break;
    }
  }
}
