import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '@core/services/session.service';
import { ConfigService } from '@core/services/config.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { trigger, transition, query, style, stagger, animate } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { StorageKey } from '@core/config/global.config';
import { UserService } from '@core/services/user.service';
import { environment } from 'src/environments/environment';

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
      {
        optional: true,
      },
    ),
    query(':leave', animate('200ms', style({ opacity: 0 })), {
      optional: true,
    }),
  ]),
]);

@Component({
  selector: 'va-user-overlay',
  template: `
    <p-overlayPanel #userDetails [showCloseIcon]="false" [style]="overlayUserStyle">
      <div class="user-header">
        <va-user-thumbs class="user-image" [firstname]="userInfo?.firstName" [lastname]="userInfo?.lastName">
        </va-user-thumbs>
        <div class="user-information">
          <h6 class="user-title">{{ userInfo?.firstName }} {{ userInfo?.lastName }}</h6>
          <p class="user-subtitle">{{ userInfo?.email }}</p>
        </div>
      </div>
      @if (multilanguage) {
        <div class="user-language">
          <label for="lang">{{ 'LANGUAGE.TITLE' | translate }}</label>
          <p-selectButton
            [options]="langOptions"
            [(ngModel)]="lang"
            (ngModelChange)="updatedLang($event)"
          ></p-selectButton>
        </div>
      }
      <div class="user-menu">
        <li class="user-link" [routerLink]="['user-profile', 'settings']">
          <i class="pi pi-cog mr-2 user-icon"></i>
          {{ 'PAGES.USER.MENU.SETTINGS' | translate | uppercase }}
        </li>
        @if (notProduction) {
          <li class="user-link" [routerLink]="['user-profile', 'release-notes']">
            <i class="pi pi-book mr-2 user-icon"></i>
            {{ 'PAGES.USER.MENU.RELEASE_NOTES' | translate | uppercase }}
          </li>
        }
        <li class="user-link" (click)="logout()">
          <i class="pi pi-sign-out mr-2 user-icon"></i>
          {{ 'PAGES.LOGIN.LOGOUT.TITLE' | translate | uppercase }}
        </li>
      </div>
    </p-overlayPanel>
    `,
  animations: [listAnimation],
})
export class UserOverlayComponent implements OnInit, OnDestroy {
  @ViewChild('userDetails') userDetails: OverlayPanel;
  @Input() userInfo: any = null;

  notProduction = !environment.production;
  multilanguage: boolean = false;
  subscription: Subscription;
  overlayUserStyle = {
    backgroundColor: '#fff',
    color: '#b3b8cd',
    width: '250px',
    margin: 'auto',
  };

  lang = this.sessionService.getItem(StorageKey.language)
    ? this.sessionService.getItem(StorageKey.language)
    : this.translateService.currentLang;

  langOptions: any[] = [
    { value: 'it', label: this.translateService.instant('LANGUAGE.IT') },
    { value: 'en', label: this.translateService.instant('LANGUAGE.EN') },
  ];

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private userService: UserService,
    private primeNGConfig: PrimeNGConfig,
    private configService: ConfigService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.configService.getConfig().subscribe((res) => {
      this.multilanguage = res.language.multilanguage;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updatedLang(lang: string): void {
    this.sessionService.setItem(StorageKey.language, lang);
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.translateService.get('PRIMENG').subscribe((res) => this.primeNGConfig.setTranslation(res));
  }

  logout(): void {
    this.userService.logout();
    this.router.navigate(['auth', 'tenant', this.sessionService.getItem('appId')]);
  }
}
