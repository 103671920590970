import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
  name: 'VehicleCategoryConverterPipe',
})
export class VehicleCategoryConverterPipe implements PipeTransform {
  constructor(private optionalValuePipe: OptionalValuePipe, private transalteService: TranslateService) {}

  transform(value: number): string {
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    let val: string;

    switch (value) {
      case 1:
        val = 'COMMONS.ENUMS.VEHICLE_CATEGORY.E_BIKES';
        break;
      case 2:
        val = 'COMMONS.ENUMS.VEHICLE_CATEGORY.BIKES';
        break;
      case 3:
        val = 'COMMONS.ENUMS.VEHICLE_CATEGORY.MOPED';
        break;
      case 4:
        val = 'COMMONS.ENUMS.VEHICLE_CATEGORY.KICKSCOOTER';
        break;
    }

    return this.transalteService.instant(val);
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
