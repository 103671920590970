import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SessionService } from '@core/services/session.service';
import { environment } from 'src/environments/environment';
import { PrimeNGConfig } from 'primeng/api';
import { filter, map } from 'rxjs/operators';
import { HeaderService } from '@layout/services/header.service';
import { SwUpdate } from '@angular/service-worker';
import { UserService } from '@core/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'va-root',
  template: `
    @if (configEnabled) {
      <va-config></va-config>
    }
    <va-notification-toast></va-notification-toast>
    <p-confirmDialog [style]="{ width: '400px' }"></p-confirmDialog>
    @if (!(sessionUserNotExist | async)) {
      <va-splash-screen></va-splash-screen>
    }
    <ng-progress [meteor]="true" [speed]="500"></ng-progress>
    <router-outlet></router-outlet>
    `,
})
export class AppComponent implements OnInit {
  configEnabled = environment.config;

  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private primeNGConfig: PrimeNGConfig,
    private translateService: TranslateService,
    private headerService: HeaderService,
    private router: Router,
    private update: SwUpdate,
  ) {
    this.updateCheck();
  }

  updateCheck(): void {
    this.update
      .checkForUpdate()
      .then(() => console.log(`resolved`))
      .catch((err) => console.log(`rejected: ${err.message}`));
  }

  ngOnInit(): void {
    document.documentElement.style.fontSize = '14px';
    this.setLanguage();
    this.getRouterData();
  }

  get sessionUserNotExist(): Observable<boolean> {
    return this.userService.isLoggedIn$.asObservable();
  }

  private getRouterData(): void {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map(() => this.router),
      )
      .subscribe(() => {
        const breadcrumbs = this.getBreadcurms(this.router.routerState, this.router.routerState.root);
        const headerTitle = breadcrumbs[breadcrumbs.length - 1];
        this.headerService.setTitle(headerTitle);
      });
  }

  private getBreadcurms(state: any, parent: any): any[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.breadcrumb) {
      data.push(parent.snapshot.data.breadcrumb);
    }

    if (state && parent) {
      data.push(...this.getBreadcurms(state, state.firstChild(parent)));
    }
    return data;
  }

  private setLanguage(): void {
    const lang = this.sessionService.getItem('language');

    if (lang) {
      this.translateService.setDefaultLang(lang);
      this.translateService.use(lang);
    }

    this.translateService.get('PRIMENG').subscribe((res) => this.primeNGConfig.setTranslation(res));
  }
}
