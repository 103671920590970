import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'va-panel-list',
  template: `
    @if (items.length > 0) {
      <a href="javascript:void(0)" class="icon-pointer" (click)="list.toggle($event)">
        {{ title | translate }}
      </a>
      <p-overlayPanel #list [showCloseIcon]="true" [style]="overlayCellsStyle">
        @if (visible) {
          <ul class="panel-list">
            @for (item of items; track item; let i = $index) {
              <li class="panel-list-item">
                {{ item }}
              </li>
            }
          </ul>
        }
      </p-overlayPanel>
    } @else {
      {{ null | optionValuePipe }}
    }
    `,
})
export class PanelListComponent implements OnInit {
  @Input() title: string;
  @Input() items: any[];
  @Input() visible = true;

  overlayCellsStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.75)',
    width: '120px',
  };

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
