import { Component, Input, OnInit } from '@angular/core';
import { VehicleTrackingMap } from '@shared/interfaces/vehicle-tracking-map.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'va-map-marker-info',
  template: `
    <div class="marker-info">
      @if (infoContent) {
        @if (infoContent?.vehicleModelCode) {
          <div class="marker-info-head">
            <img class="marker-info-img" [src]="getImage(infoContent?.vehicleModelCode, infoContent.vehicleCategory)" alt="vehicle-model" />
          </div>
        }
        <div class="marker-info-body">
          <div class="marker-info-section-head">
            <div class="marker-info-title">
              {{ infoContent?.visualId }}
            </div>
            <div class="marker-info-tags">
              @if (infoContent?.vehicleCategory) {
                <span class="marker-info-tag grey">{{
                  infoContent?.vehicleCategory
                }}</span>
              }
              @if (infoContent?.vehicleModel) {
                <span class="marker-info-tag grey">{{
                  infoContent?.vehicleModel
                }}</span>
              }
            </div>
          </div>
          @if (infoContent?.fleet) {
            <div class="marker-info-section">
              <div class="marker-info-title">
                <i class="fa fa-location-dot mr-1"></i>{{ infoContent?.fleet }}
              </div>
            </div>
          }
          @if (infoContent?.address) {
            <div class="marker-info-section">
              <div class="marker-info-title">
                {{ 'PAGES.ASSETS.VEHICLES.MAP.ACTUAL_POSITION' | translate }}
              </div>
              <div>{{ infoContent?.address }}</div>
            </div>
          }
          <div class="marker-info-section">
            <div class="marker-info-title">
              {{ 'PAGES.ASSETS.VEHICLES.MAP.CHARGE' | translate }}
            </div>
            <va-progress-bar [charge]="infoContent?.stateOfCharge"></va-progress-bar>
          </div>
          @if (infoContent?.isLinked) {
            <div class="marker-info-section-footer">
              <div class="marker-info-details">
                <a [routerLink]="['vehicle', 'details', infoContent?.id]">
                  {{ 'PAGES.ASSETS.VEHICLES.MAP.SHOW_DETAILS' | translate }}
                </a>
              </div>
            </div>
          }
        </div>
      }
    </div>
    `,
})
export class MapMarkerInfoComponent implements OnInit {
  private _infoContent: VehicleTrackingMap;

  @Input()
  set infoContent(infoContent: VehicleTrackingMap) {
    let latlng = new google.maps.LatLng(infoContent?.latitude, infoContent?.longitude);
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (res, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        infoContent.address = res[1].formatted_address;
      }

      this._infoContent = infoContent;
    });
  }

  get infoContent(): VehicleTrackingMap {
    return this._infoContent;
  }

  constructor() {}

  ngOnInit(): void {
    console.log();
  }

  getImage(model: string, category: string): string {
    let srcImg = '';
    switch (model) {
      case 'Forte V2':
      case 'Forte V1':
      case 'Forte Muscular':
        srcImg = '/assets/map/img/forte_dock.png';
        return srcImg;
      case 'FreeNoCable':
        switch(category)  {
          case 'E-Bike':
            srcImg = '/assets/map/img/libera_vaimoo.png';
          case 'Bike':
            srcImg = '/assets/map/img/libera_red.png';
            return srcImg;
        }
      case 'Free Cable':
      case 'Free 7 Marce':
        srcImg = '/assets/map/img/libera_vaimoo.png';
        return srcImg;
      case 'Muscular':
        srcImg = '/assets/map/img/libera_vaimoo.png';
        return srcImg;
      default:
        return environment.bikeImageFallback;
    }
  }
}
