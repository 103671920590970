import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { SystemService, TenantFleetResponse } from '@codegen/Vaimoo.Admin.API';
import { TenantService } from '@core/services/tenant.service';
import { tap } from 'rxjs';

export const tenantsResolver: ResolveFn<TenantFleetResponse[]> = (route, state) => {
  
  const tenantFleetService = inject(SystemService);
  const tenantService = inject(TenantService);

  return tenantFleetService.tenantFleetsGet().pipe(
    tap((tenants: TenantFleetResponse[]) => {
      tenantService.$setTenants(tenants);
    })
  )
};
