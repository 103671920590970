import { DatePipe } from '@angular/common';
import { DateConfigFilter } from '@core/config/global.config';
import { LogicEnum } from '../enum/logic.enum';
import { OperatorFilterEnum } from '../enum/operator-filter.enum';
import { TypeFilterEnum } from '../enum/type-filter.enum';
import { TypeInputEnum } from '../enum/type-input.enum';
import { FilterModel } from '../interfaces/filter-model.interface';
import moment from 'moment';

export class FiltersUtil {
  public static ObjectToArray(data: any): any[] {
    return Object.entries(data)
      .map((el) => {
        return { ...(el[1] as FilterModel), isOpen: false, isDelitable: true };
      })
      .filter(
        (el: any) =>
          typeof el.value === 'string' ||
          typeof el.value === 'number' ||
          el.value instanceof Date ||
          (Array.isArray(el.value) && el.value.length > 0 && el.value.filter((el) => el == null).length == 0),
      );
  }

  public static ArrayToObject(data: any[]): any {
    return data.reduce((obj, item) => Object.assign(obj, { [item.field]: { ...item } }), {});
  }

  public static SplitRangeValue(formValues: any): FilterModel[] {
    let data: FilterModel[] = this.ObjectToArray(formValues);

    data.map((el) => {
      switch (el.type) {
        case TypeInputEnum.NUMBER:
        case TypeInputEnum.SLIDER:
          if (Array.isArray(el.value)) {
            this.checkIsDateOrValue(data, el, false);
          }
          break;
        case TypeInputEnum.DATE:
          if (Array.isArray(el.value)) {
            this.checkIsDateOrValue(data, el, true);
          }
          break;
        case TypeInputEnum.MULTISELECTION:
          if (Array.isArray(el.value)) {
            if (el.value.length == 1) {
              el.value.forEach((e) => {
                data.push({
                  field: el.field,
                  value: e,
                  operator: el.operator,
                  logic: el.logic,
                  type: el.type,
                });
              });
            }

            if (el.value.length > 1) {
              data.push({
                field: el.field,
                value: null,
                operator: null,
                logic: LogicEnum.OR,
                filters: el.value.map((e) => {
                  return {
                    field: el.field,
                    value: e,
                    operator: el.operator,
                    logic: LogicEnum.OR,
                    type: el.type,
                  };
                }),
              });
            }
          }
          break;
        default:
          break;
      }
    });

    return data.filter((el) => !Array.isArray(el.value));
  }

  public static GetFilterModelToString(
    filters: FilterModel[],
    rows: number,
    page: number,
    sorts: Array<{ field: string; dir: string }>,
  ): string {
    let sort: Array<{ field: string; dir: string }> = sorts;
    let filterField: string = this.setFilterParams(filters, 'field');
    let filterOperator: string = this.setFilterParams(filters, 'operator');
    let filterValue: string = this.setFilterParams(filters, 'value');
    let filterLogic: string = this.setFilterParams(filters, 'logic');
    let filterFilters: FilterModel[] = this.setFilterData(filters);

    let obj = {
      pageSize: rows,
      pageIndex: page,
      sort: sort,
      filter: {
        field: filterField,
        operator: filterOperator,
        value: filterValue,
        logic: (!!filterField || filterFilters && filterFilters.length > 1) ? LogicEnum.AND : null,
        filters: filterFilters.map((el) => {
          return {
            field: el.field,
            operator: el.operator,
            value: el.value,
            logic: el.logic,
            filters: el?.filters,
          };
        }),
      },
    };

    return JSON.stringify(obj);
  }

  public static getOperators(type: string): any[] {
    return [
      {
        label: 'COMMONS.OPERATORS.EQ',
        key: OperatorFilterEnum.EQUALS,
        type: ['number', 'string'],
      },
      {
        label: 'COMMONS.OPERATORS.NEQ',
        key: OperatorFilterEnum.NOT_EQUALS,
        type: ['number', 'string'],
      },
      {
        label: 'COMMONS.OPERATORS.LT',
        key: OperatorFilterEnum.LT,
        type: ['number'],
      },
      {
        label: 'COMMONS.OPERATORS.LTE',
        key: OperatorFilterEnum.LTE,
        type: ['number'],
      },
      {
        label: 'COMMONS.OPERATORS.GT',
        key: OperatorFilterEnum.GT,
        type: ['number'],
      },
      {
        label: 'COMMONS.OPERATORS.GTE',
        key: OperatorFilterEnum.GTE,
        type: ['number'],
      },
      {
        label: 'COMMONS.OPERATORS.STARTWITH',
        key: OperatorFilterEnum.STARTSWITH,
        type: ['string'],
      },
      {
        label: 'COMMONS.OPERATORS.ENDWITH',
        key: OperatorFilterEnum.ENDSWITH,
        type: ['string'],
      },
      {
        label: 'COMMONS.OPERATORS.CONTAINS',
        key: OperatorFilterEnum.CONTAINS,
        type: ['string'],
      },
      {
        label: 'COMMONS.OPERATORS.NOT_CONTAINS',
        key: OperatorFilterEnum.NOT_CONTAINS,
        type: ['string'],
      },
    ]
      .filter((el) => el.type.includes(type))
      .map((el) => {
        return { label: el.label, key: el.key };
      });
  }

  public static getType(type: TypeFilterEnum): any {
    return [
      {
        label: 'COMMONS.TYPES.SINGLE',
        key: TypeFilterEnum.SINGLE,
      },
      {
        label: 'COMMONS.TYPES.RANGE',
        key: TypeFilterEnum.RANGE,
      },
    ].find((el) => el.key == type);
  }

  private static setFilterParams(data: FilterModel[], key: string): string {
    return data && data.length == 1 ? data[0][key] : null;
  }

  private static setFilterData(data: FilterModel[]): FilterModel[] {
    return data && data.length > 1 ? data : data;
  }

  static getFormattedUTCDateWithoutSeconds(inputDate: string): string {
    const dateObj = moment(inputDate);
    const formattedDate = dateObj.utc().format('YYYY-MM-DDTHH:mm:ss');
    return formattedDate;
  }

  private static checkIsDateOrValue(data: any, el: any, isDate: boolean): any[] {
    return data.push(
      {
        field: el.field,
        value: isDate ? this.getFormattedUTCDateWithoutSeconds(el.value[0]) : el.value[0],        
        operator: el.operator[0] as OperatorFilterEnum,
        logic: el.logic,
        type: el.type,
      },
      {
        field: el.field,
        value: el.value[1]
          ? isDate
            ? this.getFormattedUTCDateWithoutSeconds(el.value[1])
            : el.value[1]
          : isDate
          ? this.getFormattedUTCDateWithoutSeconds(el.value[0])
          : el.value[0],
        operator: el.operator[1] as OperatorFilterEnum,
        logic: el.logic,
        type: el.type,
      },
    );
  }



}
