import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient,
  HttpContext,
} from '@angular/common/http';
import { EMPTY, Observable, Subscription, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { UserService } from '@core/services/user.service';
import { RefreshTokenUrlPattern, UrlsNotUse } from '@core/config/global.config';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor, OnDestroy {
  subscription: Subscription;
  constructor(private userService: UserService, private errorService: ErrorHandlerService) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.userService.getToken();
    let appId = this.userService.getAppId();

    if (this.isValidRequestForInterceptor(request.url)) {
      if (appId) {
        request = request.clone({
          setHeaders: {
            AppId: `${appId}`,
          },
        });
      }

      if (!!token) {
        if (request.headers.has('RefreshToken'))
          request = request.clone({
            headers: request.headers.delete('Authorization'),
          });
        else {
          request = request.clone({
            setHeaders: {
              Authorization: `${token}`,
            },
          });
        }
      }
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && this.isTokenExpired(token) && !this.checkRefreshTokenUrl(request.url)) {
            return this.handleTokenRefresh(request, next);
          }
          this.errorService.handleError(err);
        }
        return of(err);
      }),
    );
  }

  checkRefreshTokenUrl(url) {
    const pattern = new RegExp(RefreshTokenUrlPattern);
    return pattern.test(url);
  }

  private isTokenExpired(token: string): boolean {
    if (!token) return true;

    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) return true;

    const payload = JSON.parse(atob(tokenParts[1]));
    const expirationTime = payload.exp * 1000;

    return Date.now() >= expirationTime;
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(position + positionIndicator.length);
      for (let address of UrlsNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }

  private handleTokenRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.userService.getRefreshToken().pipe(
      switchMap((res) => {
        if (res) {
          const modifiedRequest = this.addTokenToRequest(request, res);
          return next.handle(modifiedRequest);
        }
        throwError;
      }),
      catchError((err) => {
        console.log('ERROR IN HANDLER REFRESH', err);
        this.userService.logout();
        return EMPTY;
      }),
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `${token.accessToken.token}`,
      },
    });
  }
}
