import { Component, OnInit } from '@angular/core';
import { SystemService } from '@codegen/Vaimoo.Admin.API';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'va-version',
  template: `<div><b>FE:</b> {{ feVersion }} <b>BE:</b> {{ beVersion }}</div>`,
})
export class VersionComponent implements OnInit {
  feVersion = '';
  beVersion = '';

  constructor(private systemService: SystemService) {
    this.systemService.pingGet().subscribe((res) => {
      this.feVersion = `v${environment.buildNumber}`;
      this.beVersion = `v${res.version}`;
    });
  }

  ngOnInit(): void {
    console.log();
  }
}
