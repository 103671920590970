import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnTable } from '@shared/interfaces/column-table.interface';

@Component({
  selector: 'va-column-settings-dialog',
  template: `
    <p-dialog
      [(visible)]="show"
      [style]="{ width: '500px', height: 'auto', overflow: 'auto' }"
      [modal]="true"
      [draggable]="false"
      [closable]="false"
      >
      <ng-template pTemplate="header">
        <h3>{{ 'COMMONS.COLUMNS.TITLE' | translate }}</h3>
      </ng-template>
    
      @for (col of columns; track col) {
        <div>
          @if (!col.action) {
            <p-checkbox
              [style]="{ margin: '5px 0 5px 0' }"
              [label]="col.header | translate"
              [(ngModel)]="col.visible"
              [disabled]="disabled && col.visible"
              binary="true"
              >
            </p-checkbox>
          }
        </div>
      }
    
      <ng-template pTemplate="footer">
        <div class="float: left">
          <va-button
            [class]="'va-button-ghost'"
            [label]="'COMMONS.COLUMNS.CLOSE' | translate"
            (click)="closeColumnsSettings()"
          ></va-button>
        </div>
        <div class="float: right">
          <va-button
            [class]="'va-button-ghost'"
            [label]="'COMMONS.COLUMNS.APPLY' | translate"
            (click)="changeColumnsSettings()"
          ></va-button>
        </div>
      </ng-template>
    </p-dialog>
    `,
})
export class ColumnSettingsDialogComponent implements OnInit {
  @Input() show = false;
  @Input() columns: ColumnTable[];
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() columnsChange: EventEmitter<ColumnTable[]> = new EventEmitter<ColumnTable[]>();

  constructor() {}

  ngOnInit(): void {
    console.log();
  }

  get disabled(): boolean {
    let visible = this.columns.filter((el) => !el.visible).length;
    let media = this.columns.length / 2;

    return visible > media;
  }

  closeColumnsSettings(): void {
    this.show = false;
    this.showChange.emit(this.show);
  }

  changeColumnsSettings(): void {
    this.show = false;
    this.showChange.emit(this.show);
    this.columnsChange.emit(this.columns);
  }
}
