<va-input-layout
  [filterItem]="filterItem"
  [operatorsType]="'number'"
  (operatorChange)="operatorChange($event)"
  (typeChange)="typeChange($event)"
  (resetInput)="resetInput()"
  (restoreInput)="restoreInput($event)"
>
  <ng-container input>
    <input
      pInputText
      type="text"
      name="value"
      [value]="val"
      [style]="filterItem.optionsSlider.styleInput"
      readonly
  /></ng-container>
  <ng-container slider>
    <div style="width: 100%">
      <p-slider
        [style]="filterItem.style"
        [min]="filterItem.optionsSlider.min"
        [max]="filterItem.optionsSlider.max"
        [range]="(type | lowercase) === 'range'"
        [(ngModel)]="value"
        [step]="filterItem.optionsSlider.step"
        name="value"
      ></p-slider>
      <input [ngModel]="filterItem.key" hidden="true" name="field" />
      <input [ngModel]="operator" hidden="true" name="operator" />
      <input [ngModel]="filterItem.logic" hidden="true" name="logic" />
      <input [ngModel]="filterItem.typeInput" hidden="true" name="type" />
      <input [ngModel]="filterItem.formatValue" hidden="true" name="formatValue" />
    </div>
  </ng-container>
</va-input-layout>
