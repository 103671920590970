import { Pipe, PipeTransform } from '@angular/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
  name: 'fractionPipe',
})
export class FractionPipe implements PipeTransform {
  constructor(private optionalValuePipe: OptionalValuePipe) {}

  transform(dividend: any, divider: number): any {
    if (this.isNullOrEmptyString(dividend)) {
      return this.optionalValuePipe.transform(dividend);
    }

    return Number(dividend / divider);
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
