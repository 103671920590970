import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'va-badge-charge',
  template: `<span
    class="badge-charge"
    [class.charge-grey]="colorGray"
    [class.charge-red]="colorRed"
    [class.charge-yellow]="colorYellow"
    [class.charge-green]="colorGreen"
  >
    {{ charge === '-' ? charge : (charge | percentagePipe: 100) }}
  </span>`,
})
export class BadgeChargeComponent implements OnInit {
  @Input() charge: number | string = 0;

  constructor() {}

  ngOnInit(): void {
    console.log();
  }

  get colorGray() {
    return this.charge === null || this.charge === '-' || this.charge === undefined;
  }

  get colorRed() {
    return (
      (typeof this.charge == 'number' && this.charge >= 0 && this.charge < 25) ||
      (typeof this.charge == 'string' && Number(this.charge) >= 0 && Number(this.charge) < 25)
    );
  }

  get colorYellow() {
    return (
      (typeof this.charge == 'number' && this.charge >= 25 && this.charge < 75) ||
      (typeof this.charge == 'string' && Number(this.charge) >= 25 && Number(this.charge) < 75)
    );
  }

  get colorGreen() {
    return (
      (typeof this.charge == 'number' && this.charge >= 75 && this.charge <= 100) ||
      (typeof this.charge == 'string' && Number(this.charge) >= 75 && Number(this.charge) <= 100)
    );
  }
}
