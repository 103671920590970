import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotificationType } from '@core/enums/notification-type.enum';
import { Notification } from '@core/interfaces/notifications.interface';

@Injectable()
export class NotificationService {
  private $notificationToastrSubject = new Subject<Notification>();

  constructor(private translateService: TranslateService) {}

  listenNotificationToastr(): Observable<Notification> {
    return this.$notificationToastrSubject.asObservable();
  }

  info(description: string, title?: string): void {
    this.$notificationToastrSubject.next({
      severity: NotificationType.INFO,
      summary: this.translateService.instant(title),
      detail: description ? this.translateService.instant(description) : '',
    });
  }

  success(description: string, title?: string): void {
    this.$notificationToastrSubject.next({
      severity: NotificationType.SUCCESS,
      summary: this.translateService.instant(title),
      detail: description ? this.translateService.instant(description) : '',
    });
  }

  warning(description: string, title?: string): void {
    this.$notificationToastrSubject.next({
      severity: NotificationType.WARNING,
      summary: this.translateService.instant(title),
      detail: description ? this.translateService.instant(description) : '',
    });
  }

  error(description: string, title?: string): void {
    this.$notificationToastrSubject.next({
      severity: NotificationType.ERROR,
      summary: this.translateService.instant(title),
      detail: description ? this.translateService.instant(description) : '',
    });
  }
}
