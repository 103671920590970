<va-input-layout
  [filterItem]="filterItem"
  [operatorsType]="'number'"
  (operatorChange)="operatorChange($event)"
  (typeChange)="typeChange($event)"
  (resetInput)="resetInput()"
  (restoreInput)="restoreInput($event)"
  >
  <ng-container input>
    <div style="display: inline-flex; width: 100%" #inputAnchor>
      @switch (type) {
        @case ('Single') {
          <p-inputNumber
            [placeholder]="filterItem.placeholder | translate"
            [ngStyle]="filterItem.style"
            [(ngModel)]="value"
            [min]="filterItem.optionsNumber.single.min"
            [max]="filterItem.optionsNumber.single.max"
            [mode]="filterItem.optionsNumber.single.mode"
            [useGrouping]="filterItem.optionsNumber.single.useGrouping"
            [minFractionDigits]="filterItem.optionsNumber.single.minFractionDigits"
            [maxFractionDigits]="filterItem.optionsNumber.single.maxFractionDigits"
            name="value"
            >
          </p-inputNumber>
        }
        @case ('Range') {
          <input pInputText [ngModel]="value" name="value" hidden="true" />
          <p-inputNumber
            [placeholder]="'COMMONS.FILTERS.FROM' | translate"
            [inputStyle]="filterItem.style"
            [(ngModel)]="value[0]"
            (ngModelChange)="value[1] = $event"
            [min]="filterItem.optionsNumber.range.min"
            [max]="filterItem.optionsNumber.range.max"
            [mode]="filterItem.optionsNumber.range.mode"
            [useGrouping]="filterItem.optionsNumber.range.useGrouping"
            [minFractionDigits]="filterItem.optionsNumber.range.minFractionDigits"
            [maxFractionDigits]="filterItem.optionsNumber.range.maxFractionDigits"
            name="from"
            >
          </p-inputNumber>
          <p-inputNumber
            [placeholder]="'COMMONS.FILTERS.TO' | translate"
            [inputStyle]="filterItem.style"
            [(ngModel)]="value[1]"
            [min]="value[0] ? value[0] : 0"
            [max]="filterItem.optionsNumber.range.max"
            [mode]="filterItem.optionsNumber.range.mode"
            [useGrouping]="filterItem.optionsNumber.range.useGrouping"
            [minFractionDigits]="filterItem.optionsNumber.range.minFractionDigits"
            [maxFractionDigits]="filterItem.optionsNumber.range.maxFractionDigits"
            name="to"
          ></p-inputNumber>
        }
      }
      <input [ngModel]="filterItem.key" hidden="true" name="field" />
      <input [ngModel]="operator" hidden="true" name="operator" />
      <input [ngModel]="filterItem.logic" hidden="true" name="logic" />
      <input [ngModel]="filterItem.typeInput" hidden="true" name="type" />
      <input [ngModel]="filterItem.formatValue" hidden="true" name="formatValue" />
    </div>
  </ng-container>
</va-input-layout>
