import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SystemService } from '@codegen/Vaimoo.Admin.API';
import { environment } from 'src/environments/environment';
import { MenuItem } from '../models/menu-item.model';
import { UserOverlayComponent } from './user-overlay.component';

@Component({
  selector: 'va-navbar',
  template: `
    <div class="layout-navbar">
      @if (menuItems.length > 0) {
        <ul class="navbar">
          <a href="/" class="navbar-logo" [pTooltip]="version" [tooltipPosition]="'right'"></a>
          @for (menu of menuItems; track menu; let i = $index) {
            <li
              [class.navbar-items]="!menu?.IsSpacer"
              [class.dropdown-mobile]="!menu?.IsSpacer"
              [class.spacer]="menu?.IsSpacer"
              (click)="menu.IsUser ? userOverlay.userDetails.toggle($event) : ''"
              >
              <a
                class="navbar-link"
                #rla="routerLinkActive"
                [routerLink]="menu.RouterLink"
                [disableLink]="menu?.IsNode"
                routerLinkActive="active"
                [pTooltip]="menu.Label | translate"
                tooltipPosition="bottom"
                >
                <i class="va-icon" [ngClass]="menu?.Icon" [class.va-active]="rla.isActive"></i>
                @if (menu?.IsUser) {
                  <div style="padding: 7px">
                    <!-- <va-user-thumbs [userInfo]="userInfo"></va-user-thumbs> -->
                    <va-user-overlay #userOverlay [userInfo]="userInfo"></va-user-overlay>
                  </div>
                }
              </a>
              <div class="dropdown-mobile-content" style="left: 0">
                <ul class="section-menu">
                  @for (child of menu.Childs; track child) {
                    <li class="section-item">
                      <a
                        class="section-link"
                        #rla="routerLinkActive"
                        routerLinkActive="active-section"
                        [routerLink]="child.RouterLink"
                        >
                        {{ child.Label | translate }}
                        @if (rla.isActive && child.Childs.length === 0) {
                          <i
                            class="pi pi-arrow-right section-icon"
                          ></i>
                        }
                        @if (child.Childs.length > 0) {
                          <i
                            class="fa right"
                      [ngClass]="{
                        'fa-angle-down': !rla.isActive
                      }"
                          ></i>
                        }
                      </a>
                      @if (child.Childs && child.Childs.length > 0) {
                        <ul>
                          @for (subChild of child.Childs; track subChild) {
                            <li class="section-item-right">
                              <a
                                class="section-link"
                                #rla="routerLinkActive"
                                routerLinkActive="active-section"
                                [routerLink]="subChild.RouterLink"
                                >
                                {{ subChild.Label | translate }}
                                @if (rla.isActive && subChild.Childs.length === 0) {
                                  <i
                                    class="pi pi-arrow-right section-icon"
                                  ></i>
                                }
                                @if (subChild.Childs.length > 0) {
                                  <i
                                    class="fa right"
                                    [ngClass]="{ 'fa-angle-down': !rla.isActive }"
                                  ></i>
                                }
                              </a>
                              @if (subChild.Childs && subChild.Childs.length > 0) {
                                <ul>
                                  @for (subSubChild of subChild.Childs; track subSubChild) {
                                    <li class="section-item">
                                      <a
                                        class="section-link"
                                        #rla="routerLinkActive"
                                        routerLinkActive="active-section"
                                        [routerLink]="subSubChild.RouterLink"
                                        >
                                        {{ subSubChild.Label | translate }}
                                        @if (rla.isActive) {
                                          <i class="pi pi-arrow-right section-icon"></i>
                                        }
                                      </a>
                                    </li>
                                  }
                                </ul>
                              }
                            </li>
                          }
                        </ul>
                      }
                    </li>
                  }
                </ul>
              </div>
            </li>
          }
        </ul>
      }
    </div>
    `,
})
export class NavbarComponent implements OnInit {
  @ViewChild('userOverlay') userOverlay: UserOverlayComponent;

  @Input() menuItems: MenuItem[];
  @Input() userInfo: any;
  @Output() menuOnChange: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

  version = '';

  constructor(private systemService: SystemService) {
    this.systemService.pingGet().subscribe((res) => {
      this.version = `FE: v${environment.buildNumber} (${environment.release}) | BE: v${res.version}`;
    });
  }

  ngOnInit(): void {
    console.log();
  }
}
