import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, NgModelGroup } from '@angular/forms';
import { DateConfigFilter } from '@core/config/global.config';
import { OperatorFilterEnum } from '@shared/enum/operator-filter.enum';
import { TypeFilterEnum } from '@shared/enum/type-filter.enum';
import { DateInput } from '@shared/models/filters/input-filter-date.class';
import { FiltersService, ResetFilter } from '@shared/services/filters.service';
import moment, { MomentCreationData } from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'va-input-date',
  templateUrl: './input-date.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class InputDateComponent implements OnInit, OnDestroy {
  @Input() filterItem: DateInput;

  value: any[] | any;
  operator: OperatorFilterEnum | OperatorFilterEnum[];
  type: TypeFilterEnum;
  subscription: Subscription;
  composite: string[] = [null, null];

  constructor(private filterService: FiltersService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.type = this.filterItem.typeFilter;
    this.operator = this.type === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;

    this.getResetFilters();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  operatorChange(event: any): void {
    this.operator = event;
  }

  typeChange(event: any): void {
    this.type = event;
    this.operator = this.type === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;
  }

  setHourValue(e): void {

    if(this.type === TypeFilterEnum.RANGE && !this.filterItem?.optionsDate?.showTime)  {
      e[1] = !!e[1] ? moment(e[1]).endOf('day').toDate() : e[1];
    }

    this.value = e;
  }

  resetInput(): void {
    this.operator = this.filterItem.typeFilter === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;
    this.value = null;
  }

  restoreInput(field: any): void {
    if (typeof field.value === 'object') {
      this.value = field.value
        .map((el: any) => {
          return this.datePipe.transform(new Date(el), DateConfigFilter);
        })
        .map((el: any) => {
          let date = new Date(el.split(' ')[0]);
          date.setHours(el.split(' ')[1].split(':')[0]);
          date.setMinutes(el.split(' ')[1].split(':')[1]);

          return date;
        });
    } else {
      let date = new Date(field.value.split(' ')[0]);
      date.setHours(field.value.split(' ')[1].split(':')[0]);
      date.setMinutes(field.value.split(' ')[1].split(':')[1]);
      this.value = date;
    }
  }

  private getResetFilters(): void {
    this.subscription = this.filterService.getResetFilters().subscribe((state: ResetFilter) => {
      switch (state.type) {
        case 'restore':
          break;
        case 'resetAll':
          this.checkState(state.reset);
          break;
        case 'resetSingle':
          if (state.id === this.filterItem.key) {
            this.checkState(state.reset);
          }
          break;
      }
    });
  }

  private checkState(flag: boolean): void {
    if (flag) {
      this.value = null;
    }
  }
}
