import { Pipe, PipeTransform } from '@angular/core';
import { PipeConfig } from '@core/config/global.config';
import { FormatValue } from '@shared/enum/format-value.enum';
import { BooleanConverterPipe } from './boolean-converter.pipe';
import { GenericConverterPipe } from './generic-converter.pipe';
import { PercentagePipe } from './percentage.pipe';
import { VehiclePowerPipe } from './vehcle-power.pipe';
import { VehicleConnectedPipe } from './vehicle-connected.pipe';
import { TenantNamePipe as TenantNamePipe } from './tenant-name.pipe';

@Pipe({
  name: 'dynamicPipe',
})
export class DynamicPipe implements PipeTransform {
  public constructor(
    private vehiclePowerPipe: VehiclePowerPipe,
    private vehicleConnectedPipe: VehicleConnectedPipe,
    private genericPipe: GenericConverterPipe,
    private percentagePipe: PercentagePipe,
    private booleanPipe: BooleanConverterPipe,
    private tenantNamePipe: TenantNamePipe
  ) {}

  transform(value: string, args?: FormatValue): any {
    let val: any;

    switch (args.toString()) {
      case 'vehiclePowerPipe': {
        val = this.vehiclePowerPipe.transform(value);
        break;
      }

      case 'vehicleConnectedPipe': {
        val = this.vehicleConnectedPipe.transform(value);
        break;
      }

      case 'tenantNamePipe': {
        val = this.tenantNamePipe.transform(value);
        break;
      }

      case 'booleanPipe': {
        val = this.booleanPipe.transform(value === 'false' ? false : true);
        break;
      }

      case 'kilometresPipe': {
        if (value.includes('-')) {
          val = value
            .split('-')
            .map((el: any) =>
              this.genericPipe.transform(Number(el.trim(' ')), PipeConfig.km.symbol, PipeConfig.km.format),
            )
            .join(' - ');
        } else {
          val = this.genericPipe.transform(Number(value), PipeConfig.km.symbol, PipeConfig.km.format);
        }
        break;
      }

      case 'mVPipe': {
        if (value.includes('-')) {
          val = value
            .split('-')
            .map((el: any) =>
              this.genericPipe.transform(Number(el.trim(' ')), PipeConfig.mV.symbol, PipeConfig.mV.format),
            )
            .join(' - ');
        } else {
          val = this.genericPipe.transform(Number(value), PipeConfig.mV.symbol, PipeConfig.mV.format);
        }
        break;
      }

      case 'percentagePipe': {
        if (value.includes('-')) {
          val = value
            .split('-')
            .map((el: any) => this.percentagePipe.transform(Number(el.trim(' ')), 100))
            .join(' - ');
        } else {
          val = this.percentagePipe.transform(Number(value), 100);
        }
        break;
      }

      default: {
        val = value;
        break;
      }
    }

    return val;
  }
}
