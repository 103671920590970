import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'va-badge-power',
  template: `<span
    class="badge-power"
    [class.power-green]="power"
    [class.power-grey]="!power || power === null || power === undefined"
  >
  </span>`,
})
export class BadgePowerComponent implements OnInit {
  @Input() power: boolean;

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
