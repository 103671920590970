import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
  name: 'statePipe',
})
export class StateConverterPipe implements PipeTransform {
  constructor(private translateService: TranslateService, private optionalValuePipe: OptionalValuePipe) {}

  transform(value: 1 | 0): string {
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    return value > 0
      ? this.translateService.instant('COMMONS.ACTIVE')
      : this.translateService.instant('COMMONS.INACTIVE');
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
