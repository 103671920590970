<div class="label-container">
  <div class="start">
    <div class="label">
      {{ filterItem.label | translate }}
    </div>
  </div>
  <div class="end">
    @if (filtersAdvanced && filterItem.enableOperators && type.key === single) {
      <div class="badge-content">
        <span class="badge-operator">
          {{ operatorSingle.key | enumConverterPipe: 'OPERATORS' }}
        </span>
      </div>
    }
    @if (filtersAdvanced && filterItem.enableType) {
      <div class="badge-content">
        <span class="badge-type">{{ type.label | translate }}</span>
      </div>
    }
  </div>
</div>
<div class="p-inputgroup">
  <ng-content select="[input]"></ng-content>
  @if (filtersAdvanced && filterItem.enableOperators && type.key === single) {
    <span
      class="p-inputgroup-addon"
      >
      <va-operators-filter
        [operators]="operators"
        [op]="operatorSingle"
        (opChange)="operatorOnChange($event)"
      ></va-operators-filter>
    </span>
  }
  @if (filtersAdvanced && filterItem.enableType) {
    <span class="p-inputgroup-addon">
      <va-types-filter [type]="type" (typeChange)="typeOnChange($event)"></va-types-filter>
    </span>
  }
  @if (filterItem.typeInput !== 'dropdown' && filterItem.typeInput !== 'multiselection') {
    <span
      class="p-inputgroup-addon"
      >
      <i class="pi pi-trash icon-pointer" (click)="typeOnChange(type)"></i>
    </span>
  }
</div>
<ng-content select="[slider]"></ng-content>
