import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
  UrlSegment,
} from '@angular/router';
import { NotificationService } from '@core/services/notification.service';
import { UserService } from '@core/services/user.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {
  title: string;
  desc: string;
  token: string;
  appId: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
  ) {
    this.appId = this.userService.getAppId();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if (this.toHaveAccess) {
      return true;
    }

    this.notificationService.error(this.desc, this.title);
    this.router.navigate(['auth', 'tenant', this.appId]);

    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.toHaveAccess) {
      return true;
    }

    this.notificationService.error('COMMONS.ERROR.ROUTING_ERROR', 'COMMONS.ERROR.TITLE');
    this.router.navigate(['auth', 'tenant', this.appId], {
      queryParams: { returnUrl: state.url },
    });

    return false;
  }

  private get toHaveAccess(): boolean {
    let isLoggedIn;

    this.userService.isLoggedIn$.subscribe((res) => {
      isLoggedIn = res;
    });

    return isLoggedIn;
  }
}
