import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BASE_PATH as BASE_PATH_ADMIN_API } from '@codegen/Vaimoo.Admin.API';
import { BASE_PATH as BASE_PATH_POWER_BI } from '@codegen/Vaimoo.PowerBI.API';
import { SilentApi } from '@core/config/global.config';
import { CoreModule } from '@core/core.module';
import { HttpResponseInterceptor } from '@core/interceptors/http-response.interceptor';
import { UserService } from '@core/services/user.service';
import { LayoutModule } from '@layout/layout.module';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { environment } from 'src/environments/environment';
import { AppConfigComponent } from './app-config.component';
import { ApplicationInitializerFactory } from './app-init';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

function checkTokenExpiration(userService: UserService) {
  return () => {
    return new Promise((resolve) => {
      console.log('checkTokenExpiration');
      //@ts-ignore
      userService.checkTokenExpiration().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [AppComponent, AppConfigComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    LayoutModule,
    NgProgressModule,
    
    NgProgressHttpModule.withConfig({
      silentApis: SilentApi,
    }),
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: BASE_PATH_ADMIN_API,
      useValue: environment.baseUrlAdminApi,
    },
    {
      provide: BASE_PATH_POWER_BI,
      useValue: environment.baseUrlPowerBIApi,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: checkTokenExpiration,
    //   multi: true,
    //   deps: [UserService],
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [Injector, TranslateService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
