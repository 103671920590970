import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ControlContainer, NgModelGroup } from '@angular/forms';
import { OperatorFilterEnum } from '@shared/enum/operator-filter.enum';
import { TypeFilterEnum } from '@shared/enum/type-filter.enum';
import { NumberInput } from '@shared/models/filters/input-filter-number.class';
import { FiltersService, ResetFilter } from '@shared/services/filters.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'va-input-number',
  templateUrl: './input-number.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class InputNumberComponent implements OnInit, OnDestroy {
  @Input() filterItem: NumberInput;

  value: any;
  operator: OperatorFilterEnum | OperatorFilterEnum[];
  type: TypeFilterEnum;
  subscription: Subscription;

  constructor(private filterService: FiltersService) {}

  ngOnInit(): void {
    this.type = this.filterItem.typeFilter;
    this.operator = this.type === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;

    this.getResetFilters();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  operatorChange(event: any): void {
    this.operator = event;
  }

  typeChange(event): void {
    this.type = event;
    this.operator = this.type === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;
  }

  resetInput(): void {
    this.operator =
      this.filterItem.typeFilter === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;
    this.value = this.filterItem.typeFilter === 'Range' ? [] : null;
  }

  restoreInput(field: any): void {
    this.value = field.value;
  }

  private getResetFilters(): void {
    this.subscription = this.filterService.getResetFilters().subscribe((state: ResetFilter) => {
      switch (state.type) {
        case 'restore':
          break;
        case 'resetAll':
          this.checkState(state.reset);
          break;
        case 'resetSingle':
          if (state.id === this.filterItem.key) {
            this.checkState(state.reset);
          }
          break;
      }
    });
  }

  private checkState(flag: boolean): void {
    if (flag) {
      this.value = this.type === 'Range' ? [] : null;
    }
  }
}
