import { Injectable, OnDestroy, signal } from '@angular/core';
import { TenantFleetResponse } from '@codegen/Vaimoo.Admin.API';

export interface DropdownResult {
  value: string; 
  label: string;
}

@Injectable()
export class TenantService implements OnDestroy {

  private $tenants = signal<TenantFleetResponse[]>([]);

  $setTenants(tenants: TenantFleetResponse[]) {
    this.$tenants.set(tenants);
  }

  $getTenants() {
    return this.$tenants;
  }

  getTenants() {
    return this.$tenants();
  }

  getTenantName(tenantId: string) {
    const tenants = this.getTenants();
    return tenants?.find(x => x.tenantId == tenantId).tenantName;
  }

  getAllTenants() {
    return this.$tenants();
  }

  getTenantsEnumeration(): DropdownResult[] {
    return this.getTenants().map(x => {
        return {
          label: x.tenantName,
          value: x.tenantId
        }
      });
  }
  
  ngOnDestroy(): void {}

}
