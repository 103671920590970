import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'va-toggle',
  template: `<p-inputSwitch [(ngModel)]="checked" [disabled]="disabled"></p-inputSwitch>`,
})
export class TooggleComponent implements OnInit {
  @Input() checked = false;
  @Input() disabled = false;

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
