import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'va-empty-layout',
  template: `<router-outlet></router-outlet>`,
})
export class EmptyLayoutComponent implements OnInit {
  appId: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      if (param['appId']) {
        this.appId = param['appId'];
      }
    });
  }
}
