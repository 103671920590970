import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ColumnTable } from '@shared/interfaces/column-table.interface';
import { VehicleTrackingMap } from '@shared/interfaces/vehicle-tracking-map.interface';
import { MapService } from '@shared/services/map.service';
import { MenuItem } from 'primeng/api';
import { RolesType } from '@core/enums/roles-type.enum';

@Component({
  selector: 'va-map-marker-cluster-info',
  template: `
    <div class="marker-cluster-info">
      <p-table
        styleClass="p-datatable-striped p-datatable-sm"
        responsiveLayout="scroll"
        [value]="markers"
        [rowHover]="true"
        [paginator]="true"
        [globalFilterFields]="['visualId']"
        [rows]="3"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>
              {{ 'PAGES.ASSETS.VEHICLES.MAP.TABLE.VISUAL_ID' | translate }}
              <p-columnFilter
                type="text"
                field="visualId"
                display="menu"
                [showMatchModes]="false"
                [showOperator]="false"
                [showAddButton]="false"
              ></p-columnFilter>
            </th>
            <th>
              {{ 'PAGES.ASSETS.VEHICLES.MAP.TABLE.VEHICLE_ID' | translate }}
            </th>
            <th>
              {{ 'FLEET' | translate }}
            </th>
            <th>
              {{ 'PAGES.ASSETS.VEHICLES.MAP.TABLE.CHARGE' | translate }}
            </th>
            <th>
              {{ 'PAGES.ASSETS.VEHICLES.MAP.TABLE.MODEL' | translate }}
            </th>
            <th>
              {{ 'PAGES.ASSETS.VEHICLES.MAP.TABLE.CATEGORY' | translate }}
            </th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-marker>
          <tr>
            <td>{{ marker.visualId }}</td>
            <td>{{ marker.id }}</td>
            <td>{{ marker.fleet }}</td>
            <td>
              <va-badge-charge [charge]="marker.stateOfCharge"></va-badge-charge>
            </td>
            <td>{{ marker.vehicleModel }}</td>
            <td>{{ marker.vehicleCategory }}</td>
            <td>
              <va-button-event
                [icon]="'fas fa-map-marker-alt icon-pointer'"
                (clickIcon)="clickIcon(marker.id)"
              ></va-button-event>
            </td>
            <td>
              <va-table-menu
                [label]="'COMMONS.TABLE.MENU_TABLE.VIEW' | translate"
                [link]="['vehicle', 'details', marker.id.toString()]"
                [items]="getItems(marker)"
              ></va-table-menu>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
})
export class MapMarkerClusterInfoComponent {
  @Input() markers: VehicleTrackingMap[];
  columns: ColumnTable[];
  appId: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private mapService: MapService,
    private translateService: TranslateService,
  ) {
    this.appId = this.userService.getAppId();
  }

  clickIcon(markerId: number): void {
    this.mapService.setMarkerId(markerId);
  }

  getItems(marker: any): MenuItem[] {
    return [
      {
        label: this.translateService.instant('PAGES.ASSETS.VEHICLES.TABLE_MENU.DIAGNOSTIC'),
        command: () =>
          this.router.navigate([
            'tenant',
            this.appId,
            'assets',
            'vehicles',
            'vehicle',
            'diagnostic',
            marker.id,
          ]),
        visible: true,
      },
      {
        label: this.translateService.instant('PAGES.ASSETS.VEHICLES.TABLE_MENU.ADD_REPAIR'),
        command: () =>
          this.router.navigate(['tenant', this.appId, 'maintenance', 'repairs', 'kanban'], {
            queryParams: { visualId: marker.visualId },
            queryParamsHandling: 'merge',
          }),
        visible: this.userService.getUserRoles().includes(RolesType.ADMIN_REPAIRS),
      },
    ];
  }
}
