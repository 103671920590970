import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleBuilder } from '@core/enums/roles-type.enum';
import { AuthGuard } from '@core/guards/auth.guard';
import { LoggedGuard } from '@core/guards/logged.guard';
import { NodeGuard } from '@core/guards/node.guard';
import { LayoutComponent } from '@layout/components/layout.component';
import { NotAuthorizedComponent } from './features/errors/not-authorized.component';
import { NotFoundComponent } from './features/errors/not-found.component';
import { tenantsResolver } from '@core/resolvers/tenants.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'auth/tenant', pathMatch: 'full' },
  {
    path: 'auth/tenant/:appId',
    loadChildren: () => import('src/app/features/login/login.module').then((m) => m.LoginModule),
    canLoad: [LoggedGuard],
  },
  {
    path: 'auth/tenant',
    loadChildren: () => import('src/app/features/login/login.module').then((m) => m.LoginModule),
    canLoad: [LoggedGuard],
  },
  {
    path: 'tenant/:appId',
    component: LayoutComponent,
    resolve: {
      tenants: tenantsResolver
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'assets' },
      {
        path: 'dashboard',
        data: {
          role: [RoleBuilder.ROLES_DASHBOARD_POWER_BI],
          breadcrumb: null,
          title: 'Dashboard',
        },
        loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canLoad: [NodeGuard],
      },
      {
        path: 'assets',
        data: {
          role: [
            RoleBuilder.ROLES_VEHICLES,
            RoleBuilder.ROLES_BATTERIES,
            RoleBuilder.ROLES_DOCKING_POINT,
            RoleBuilder.ROLES_IOT_MODULE,
          ],
          breadcrumb: null,
          title: 'Assets',
        },
        loadChildren: () => import('./features/assets/assets.module').then((m) => m.AssetsModule),
        canLoad: [NodeGuard],
      },
      {
        path: 'ota-updates-manager',
        data: {
          role: [RoleBuilder.ROLES_OTA_UPDATES_MANAGER],
          breadcrumb: null,
          title: 'Ota Updates Manager',
        },
        loadChildren: () =>
          import('./features/ota-updates-manager/ota-updates-manager.module').then(
            (m) => m.OtaUpdatesManagerModule,
          ),
        canLoad: [NodeGuard],
      },
      {
        path: 'customers',
        data: {
          role: [
            RoleBuilder.ROLES_DASHBOARD,
            RoleBuilder.ROLES_PEOPLE,
            RoleBuilder.ROLES_COMPANIES,
            RoleBuilder.ROLES_MEMBERSHIP,
            RoleBuilder.ROLES_CUSTOMER_SATISFACTION,
          ],
          breadcrumb: null,
          title: 'Customers',
        },
        loadChildren: () => import('./features/customers/customers.module').then((m) => m.CustomersModule),
        canLoad: [NodeGuard],
      },
      {
        path: 'trips',
        data: {
          role: [RoleBuilder.ROLES_TRIPS],
          breadcrumb: null,
          title: 'Trips',
        },
        loadChildren: () => import('./features/trips/trips.module').then((m) => m.TripsModule),
        canLoad: [NodeGuard],
      },
      {
        path: 'maintenance',
        data: {
          role: [
            RoleBuilder.ROLES_REPAIRS,
            RoleBuilder.ROLES_ISSUE,
            RoleBuilder.ROLE_MAINTENANCE_PLAN,
            RoleBuilder.ROLES_REDISTRIBUTION,
          ],
          breadcrumb: null,
          title: 'Maintenance',
        },
        loadChildren: () =>
          import('./features/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
        canLoad: [NodeGuard],
      },
      {
        path: 'sharing',
        data: {
          role: [
            RoleBuilder.ROLES_STATION_PHYSICAL,
            RoleBuilder.ROLES_STATION_VIRTUAL,
            RoleBuilder.ROLES_PRICING_PLANS,
            RoleBuilder.ROLES_RESERVATIONS,
            RoleBuilder.ROLES_BOOKINGS,
          ],
          breadcrumb: null,
          title: 'Sharing',
        },
        loadChildren: () => import('./features/sharing/sharing.module').then((m) => m.SharingModule),
        canLoad: [NodeGuard],
      },

      {
        path: 'accounting',
        data: {
          role: [
            RoleBuilder.ROLES_PAYMENTS,
            RoleBuilder.ROLES_INVOICES,
            RoleBuilder.ROLES_CREDIT_CARDS,
            RoleBuilder.ROLES_BOOKKEEPING,
          ],
          breadcrumb: null,
          title: 'Accounting',
        },
        loadChildren: () => import('./features/accounting/accounting.module').then((m) => m.AccountingModule),
        canLoad: [NodeGuard],
      },
      {
        path: 'search',
        data: {
          role: [RoleBuilder.ROLES_SEARCH],
          breadcrumb: null,
          title: 'Search',
        },
        loadChildren: () => import('./features/search/search.module').then((m) => m.SearchModule),
        canLoad: [NodeGuard],
      },
      {
        path: 'configurations',
        data: {
          role: [
            RoleBuilder.ROLES_CONF_ORGANIZATIONS_TEAMS,
            RoleBuilder.ROLES_CONF_ORGANIZATIONS_USERS,
            RoleBuilder.ROLES_CONF_ASSETS_VEHICLE_MODELS,
            RoleBuilder.ROLES_CONF_ASSETS_IOT_MODULE_TYPES,
            RoleBuilder.ROLES_CONF_ASSETS_BATTERY_TYPES,
            RoleBuilder.ROLES_CONF_SYSTEM_LANGUAGES,
            RoleBuilder.ROLES_CONF_SYSTEM_BRANDING_COLORS,
            RoleBuilder.ROLES_CONF_SYSTEM_BRANDING_LOGOS,
            RoleBuilder.ROLES_CONF_SYSTEM_TEMPLATES,
            RoleBuilder.ROLES_CONF_SYSTEM_API_KEYS,
            RoleBuilder.ROLES_CONF_PAYMENTS_PAYMENT_GATEWAYS,
            RoleBuilder.ROLES_CONF_PAYMENTS_PRODUCTS,
            RoleBuilder.ROLES_CONF_SERVICE_ISSUE_CATEGORY,
          ],
          breadcrumb: null,
          title: 'Configurations',
        },
        loadChildren: () =>
          import('./features/configurations/configurations.module').then((m) => m.ConfigurationsModule),
        canLoad: [NodeGuard],
      },
      {
        path: 'tenants',
        data: {
          breadcrumb: 'MENU.LIST.TENANT.TITLE',
          title: 'Tenants',
        },
        loadChildren: () => import('./features/tenants/tenants.module').then((m) => m.TenantsModule),
        canLoad: [AuthGuard],
      },
      {
        path: 'user-profile',
        data: {
          breadcrumb: null,
          title: 'User Profile',
        },
        loadChildren: () => import('./features/user/user.module').then((m) => m.UserModule),
        canLoad: [AuthGuard],
      },
    ],
  },
  {
    path: 'error/404',
    component: NotFoundComponent,
  },
  {
    path: 'error/401',
    component: NotAuthorizedComponent,
  },
  { path: '**', redirectTo: 'error/404' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
