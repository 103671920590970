import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from '@layout/models/menu-item.model';
import { MenuService } from '@layout/services/menu.service';
import { SidebarService } from '@layout/services/sidebar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'va-menu',
  template: `
    <div class="layout-container-menu" [class.open]="active" [class.close]="!active">
      <div class="layout-menu">
        <div class="section-header">
          <div class="section-title">
            <h4>Menu</h4>
          </div>
          <div class="section-close">
            <va-button-event [icon]="'pi pi-times'" (clickIcon)="closeClick()"></va-button-event>
          </div>
        </div>
        <div class="section-menu">
          <ul class="mainmenu">
            @for (menu of menuItems; track menu) {
              @if (!menu.IsSpacer && !menu['IsTenant']) {
                <li>
                  <a
                    #firstLevel="routerLinkActive"
                    [routerLink]="menu.RouterLink"
                    [routerLinkActive]="'active-link'"
                    (click)="closeClick()"
                    >
                    {{ menu.Label | translate }}
                  </a>
                  <ul class="child">
                    @for (child of menu.Childs; track child) {
                      <li>
                        <a
                          #secondLevel="routerLinkActive"
                          [routerLink]="child.RouterLink"
                          [routerLinkActive]="'active-link'"
                          (click)="closeClick()"
                          >
                          {{ child.Label | translate }}
                        </a>
                        <ul class="subChild">
                          @for (subChild of child.Childs; track subChild) {
                            <li>
                              <a
                                #thirdLevel="routerLinkActive"
                                [routerLink]="subChild.RouterLink"
                                [routerLinkActive]="'active-link'"
                                (click)="closeClick()"
                                >
                                {{ subChild.Label | translate }}
                              </a>
                              <ul class="subSubChild">
                                @for (subSubChild of subChild.Childs; track subSubChild) {
                                  <li>
                                    <a
                                      #fourthLevel="routerLinkActive"
                                      [routerLink]="subSubChild.RouterLink"
                                      [routerLinkActive]="'active-link'"
                                      (click)="closeClick()"
                                      >
                                      {{ subSubChild.Label | translate }}
                                    </a>
                                  </li>
                                }
                              </ul>
                            </li>
                          }
                        </ul>
                      </li>
                    }
                  </ul>
                </li>
              }
            }
          </ul>
        </div>
      </div>
    </div>
    `,
})
export class MenuComponent implements OnInit {
  active = false;
  subscription: Subscription;
  menuItems: MenuItem[] = [];

  constructor(private menuService: MenuService, private sidebarService: SidebarService) {
    this.sidebarService.getStateMobile().subscribe((state) => {
      this.active = state;
    });
  }

  ngOnInit(): void {
    this.getMenuItems();
  }

  closeClick() {
    this.sidebarService.toggleSidebarMobile();
  }

  private getMenuItems(): void {
    this.subscription = this.menuService.getMenu().subscribe((menuItems) => {
      this.menuItems = menuItems;
    });
  }
}
