import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VehicleTrackingMap } from '@shared/interfaces/vehicle-tracking-map.interface';

@Component({
  selector: 'va-map-filter',
  template: `
    @if (menuState === 'in') {
      <div [@slideInOut]="menuState" class="map-filter-container">
        <div class="map-filter-header">
          <h5>Show on map:</h5>
          <div class="close-icon">
            <va-button-event [icon]="'pi pi-times '" (clickIcon)="toggleMenu()"></va-button-event>
          </div>
        </div>
        <div class="map-filter-body">
          <p-tabView>
            <p-tabPanel header="Vehicles">
              @for (v of vehicles; track v) {
                <p class="name-section">{{ v.category }}</p>
                <ul>
                  @for (t of v.types; track t) {
                    <li class="vehicle-type">
                      <label class="vehicle-model" [for]="types">{{ t.label }}</label>
                      <p-checkbox
                        class="checkbox"
                        [(ngModel)]="t.value"
                        (ngModelChange)="checkboxChange($event, t.type)"
                        [disabled]="t.disabled"
                        binary="true"
                      ></p-checkbox>
                    </li>
                  }
                </ul>
              }
            </p-tabPanel>
            <p-tabPanel header="Stations"></p-tabPanel>
            <p-tabPanel header="Hardwares"></p-tabPanel>
          </p-tabView>
        </div>
      </div>
    }
    `,
  styles: [
    `
      :host ::ng-deep .p-tabview .p-tabview-panels {
        background-color: transparent !important;
      }

      :host ::ng-deep .p-tabview .p-tabview-nav {
        display: flex;
        justify-content: center;
      }
    `,
  ],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(0,0,0)',
        }),
      ),
      state(
        'out',
        style({
          transform: 'translate3d(100%, 0, 0)',
        }),
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class MapFilterComponent {
  private _markers: VehicleTrackingMap[];

  @Input() menuState: 'out' | 'in' = 'out';
  @Input()
  set markers(markers: VehicleTrackingMap[]) {
    this._markers = markers;
    this.vehicles = this.getVehiclesCategory(markers);
  }
  get markers(): VehicleTrackingMap[] {
    return this._markers;
  }

  @Output() markersChange: EventEmitter<VehicleTrackingMap[]> = new EventEmitter<VehicleTrackingMap[]>();
  @Output() menuStateChange: EventEmitter<string> = new EventEmitter<string>();

  vehicles: any[] = [];
  types: any[] = [
    { type: 1, value: 'E-Bike' },
    { type: 2, value: 'Bike' },
    { type: 3, value: 'Moped' },
    { type: 4, value: 'Kickscooter' },
  ];

  toggleMenu(): void {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.menuStateChange.emit(this.menuState);
  }

  checkboxChange(event: boolean, typeId: number): void {
    if (event) {
      this.markersChange.emit(this.markers.filter((el) => el.typeId === typeId));
    } else {
      this.markersChange.emit(this.markers.filter((el) => el.typeId !== typeId));
    }
  }

  getVehiclesCategory(markers: VehicleTrackingMap[]): any[] {
    return [
      {
        category: 'Electric',
        types: [
          {
            type: 1,
            label: 'E-Bike',
            value: markers.filter((el) => el.typeId === 1).length > 0,
            disabled: markers.filter((el) => el.typeId === 1).length === 0,
          },
          {
            type: 3,
            label: 'Moped',
            value: markers.filter((el) => el.typeId === 3).length > 0,
            disabled: markers.filter((el) => el.typeId === 3).length === 0,
          },
        ],
      },
      {
        category: 'Motors',
        types: [
          {
            type: 4,
            label: 'Kickscooter',
            value: markers.filter((el) => el.typeId === 4).length > 0,
            disabled: markers.filter((el) => el.typeId === 4).length === 0,
          },
        ],
      },
      {
        category: 'Muscular',
        types: [
          {
            type: 2,
            label: 'Bike',
            value: markers.filter((el) => el.typeId === 2).length > 0,
            disabled: markers.filter((el) => el.typeId === 2).length === 0,
          },
        ],
      },
    ];
  }
}
