import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'va-badge',
  template: `<span class="badge" [ngClass]="color">{{ value }}</span>`,
})
export class BadgeComponent implements OnInit {
  @Input() value: any;
  @Input() color: 'blue' | 'green' | 'red' | 'yellow';

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
