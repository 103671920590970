import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { LayoutComponent } from './components/layout.component';
import { MenuComponent } from './components/menu.component';
import { NavbarComponent } from './components/navbar.component';
import { SectionComponent } from './components/section.component';
import { SidebarComponent } from './components/sidebar.component';
import { UserOverlayComponent } from './components/user-overlay.component';
import { HeaderService } from './services/header.service';
import { MenuService } from './services/menu.service';

const COMPONENTS = [
  LayoutComponent,
  MenuComponent,
  NavbarComponent,
  SectionComponent,
  SidebarComponent,
  UserOverlayComponent,
];

export const SERVICES = [HeaderService, SidebarComponent, MenuService];

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  providers: [...SERVICES],
})
export class LayoutModule {}
