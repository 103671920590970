<i class="pi pi-filter icon-pointer" (click)="panel.toggle($event)"></i>
<p-overlayPanel #panel [showCloseIcon]="true" [style]="overlayStyle">
  <ul class="item-ot">
    @for (o of operators; track o) {
      <li>
        <p-radioButton
          class="desc"
          [inputId]="o.key"
          [name]="o"
          [value]="o"
          [label]="o.label | translate"
          [(ngModel)]="selected"
          (click)="opChange.emit(o)"
        ></p-radioButton>
      </li>
    }
  </ul>
</p-overlayPanel>
