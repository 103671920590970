export enum RolesType {
  ADMIN_BATTERIES = 'AdminBatteries',
  ADMIN_BATTERY_TYPE = 'AdminBatteryType',
  ADMIN_BIKE_LOG = 'AdminBikeLog',
  ADMIN_BIKE_STATUSES = 'AdminBikeStatuses',
  ADMIN_BIKE_TRACKS = 'AdminBikeTracks',
  ADMIN_BIKES = 'AdminBikes',
  ADMIN_REPAIRS = 'AdminRepairs',
  ADMIN_REPORTS = 'AdminReports',
  ADMIN_TABLETS = 'AdminTablets',
  ADMIN_WEBSITE = 'AdminWebsite',
  API_BIKE_EVENT_BATTERY_LEVEL_CHANGE = 'ApiBikeEventBatteryLevelChange',
  ADMIN_POWER_BI = 'AdminPowerBI',
}

export const AllRoles = [
  'AdminSettings',
  'AdminLocalization',
  'AdminSystemLog',
  'AdminLanguages',
  'AdminBikeLog',
  'AdminTabletTypes',
  'AdminFiles',
  'AdminUserRoles',
  'AdminUsers',
  'AdminReservedUserNames',
  'AdminTablets',
  'AdminFilesForTabletTypes',
  'AdminBikes',
  'AdminDockingStations',
  'AdminDockingPoints',
  'AdminBikeTracks',
  'AdminClients',
  'AdminSaOnly',
  'AdminTabletCommands',
  'AdminRequestResponseLog',
  'AdminPaymentForms',
  'AdminProducts',
  'AdminSubscriptions',
  'AdminPriceLists',
  'AdminInvoices',
  'AdminIssueCategories',
  'AdminQuestionCategories',
  'AdminAccessTokens',
  'AdminBikeStatuses',
  'AdminRepairs',
  'AdminBookings',
  'AdminReservations',
  'AdminTransactions',
  'AdminPaymentOrders',
  'AdminTrips',
  'AdminPaymentTransactions',
  'AdminTemplates',
  'AdminSystemNotifications',
  'AdminBikeLiveStatuses',
  'AdminBatteries',
  'AdminPcbs',
  'AdminReports',
  'AdminBatteryType',
  'AdminSignalLevel',
  'AdminSiteAdminLog',
  'AdminRoutes',
  'AdminBonuses',
  'AdminStatistics',
  'AdminCreditCards',
  'AdminWebsite',
  'AdminSuspiciousBikes',
  'AdminUserSystemNotifications',
  'AdminPaymentOrderBatchSetup',
  'AdminPaymentOrderBatch',
  'AdminPaymentOrderBatchExport',
  'AdminPaymentTransactionFunding',
  'AdminPaymentTransactionFundingList',
  'AdminBookkeepingAccounts',
  'AdminPaymentOrdersRefund',
  'AdminPaymentOrdersRefundPartial',
  'AdminPaymentOrdersCancel',
  'AdminBookkeepingAccountsCashFlowSetup',
  'AdminCompanies',
  'AdminCompanyGroups',
  'AdminCompanySubscriptions',
  'AdminCompanyRequests',
  'AdminQuestions',
  'AdminQuestionResults',
  'AdminPcbFirmwareVersionHistory',
  'AdminRedistribution',
  'AdminAccessConstraintByIp',
  'AdminUserAdd',
  'AdminUserEdit',
  'AdminUserEditRole',
  'AdminUserEditSensitiveInfo',
  'AdminUsersDeleteFromDb',
  'AdminReportingServerRole',
  'AdminUserLoyaltyPointSetup',
  'AdminUserLoyaltyPoint',
  'AdminUserLoyaltyLevel',
  'AdminUserLoyaltyLevelFeatureValue',
  'AdminFullSensitiveDataAccess',
  'AdminScheduledTaskEndpointCalls',
  'AdminSensitiveDataAccessLog',
  'AdminPerformanceCounterLogs',
  'ApiFileDownload',
  'ApiFileUpdate',
  'ApiTabletInit',
  'ApiTime',
  'ApiFileConfirm',
  'ApiAuthTravelCard',
  'ApiFileCertificateDownload',
  'ApiUserPatchTravelCard',
  'ApiBikeEventDock',
  'ApiDockingPointCreate',
  'ApiBikeEventUndock',
  'ApiBikeEventLock',
  'ApiBikeEventUnlock',
  'ApiBookingTicketCreate',
  'ApiBookingTicketRelease',
  'ApiTripStart',
  'ApiTripEnd',
  'ApiCommandPoll',
  'ApiCommandConfirm',
  'ApiBookingCreate',
  'ApiBookingCancel',
  'ApiDockingStationReturnInfoGet',
  'ApiDockingStationBookingInfoGet',
  'ApiReservationCreate',
  'ApiReservationCancel',
  'ApiBikeReportCreate',
  'ApiDockingPointReportCreate',
  'ApiBikeRepairCreate',
  'ApiDockingPointRepairCreate',
  'ApiTripCostGet',
  'ApiBikeEventEngineLevelChange',
  'ApiUserPatch',
  'ApiUserCreate',
  'ApiUserPatchPassword',
  'ApiUserPatchEmail',
  'ApiUserDelete',
  'ApiBikeCreate',
  'TabletMaintenanceMenu',
  'ApiTripList',
  'ApiTripDetails',
  'ApiBookingList',
  'ApiReservationList',
  'ApiBikeEventPowerOff',
  'ApiInvoiceDetails',
  'ApiInvoiceList',
  'ApiServerClearCache',
  'ApiPaymentOrderCreate',
  'ApiContentGet',
  'ApiUserPasswordRestore',
  'ApiDockingStationList',
  'ApiPaymentOrderCapture',
  'ApiDockingPointLiveStatusUpdate',
  'ApiRouteList',
  'ApiRouteDetails',
  'ApiRouteCreate',
  'ApiFirmwareStatusUpdate',
  'ApiUserSubscriptionList',
  'ApiUserSubscriptionPatch',
  'ApiRoutePatch',
  'ApiRouteDelete',
  'ApiSocialConnect',
  'ApiSocialDisconnect',
  'ApiSocialShare',
  'ApiPaymentOrderList',
  'ApiPaymentOrderDetails',
  'ApiReservationRuleCreate',
  'ApiReservationRuleGet',
  'ApiReservationRuleList',
  'ApiReservationRuleDelete',
  'ApiExternalMembershipConnectDsb',
  'ApiSignalLevelLog',
  'ApiBikeList',
  'ApiBikeEventLockPeriodExceeded',
  'ApiExternalMembershipConnectGoBikeVoucher',
  'ApiSettingsList',
  'ApiBikeEventTripDurationWarning',
  'ApiViewDocs',
  'ApiExternalMembershipConnect',
  'ApiBikeEventBatteryLevelChange',
  'ApiUserRemoveTravelCard',
  'ApiBikeRepairGet',
  'ApiRepairsGet',
  'ApiRepairEnd',
  'ApiRepairCreate',
  'ApiIssueCategories',
  'ApiCompanyCreate',
  'ApiCompanyPatch',
  'ApiCompanyGet',
  'ApiCompanyDelete',
  'ApiCompanyUserCreate',
  'ApiCompanyUserDelete',
  'ApiCompanyUserChangeStatus',
  'ApiCompanyUserResetPassword',
  'ApiCompanyReports',
  'ApiBikeEventService',
  'ApiRepairStart',
  'ApiQuestionsGet',
  'ApiQuestionResultsPost',
  'ApiViewInternalDocs',
  'HotelSolutionContentManagement',
  'HotelSolutionOwnerSignIn',
  'ApiUserPhoneValidate',
  'ApiUserEmailValidate',
  'ApiUserHJHSignup',
  'ContentManager',
];

export class RoleBuilder {
  /********************************************************************/
  /*********************** DASHBOARD POWER BI ************************/
  /******************************************************************/

  static ROLES_DASHBOARD_POWER_BI = [RolesType.ADMIN_POWER_BI];

  /*******************************************************/
  /*********************** ASSETS ***********************/
  /*****************************************************/

  static ROLES_VEHICLES = [
    RolesType.ADMIN_BIKES,
    RolesType.ADMIN_BIKE_TRACKS,
    RolesType.ADMIN_BIKE_STATUSES,
    RolesType.ADMIN_WEBSITE,
  ];
  static ROLES_BATTERIES = [
    RolesType.ADMIN_BATTERIES,
    RolesType.ADMIN_BATTERY_TYPE,
    RolesType.API_BIKE_EVENT_BATTERY_LEVEL_CHANGE,
  ];
  static ROLES_DOCKING_POINT = [];
  static ROLES_IOT_MODULE = [];

  /********************************************************************/
  /*********************** OTA UPDATES MANAGER ***********************/
  /******************************************************************/

  static ROLES_OTA_UPDATES_MANAGER = [RolesType.ADMIN_TABLETS];

  /**********************************************************/
  /*********************** DASHBOARD ***********************/
  /********************************************************/

  static ROLES_DASHBOARD = [];
  static ROLES_PEOPLE = [];
  static ROLES_COMPANIES = [];
  static ROLES_MEMBERSHIP = [];
  static ROLES_CUSTOMER_SATISFACTION = [];

  /******************************************************/
  /*********************** TRIPS ***********************/
  /****************************************************/

  static ROLES_TRIPS = [];

  /******************************************************/
  /*********************** SHARING *********************/
  /****************************************************/

  static ROLES_STATION_PHYSICAL = [];
  static ROLES_STATION_VIRTUAL = [];
  static ROLES_PRICING_PLANS = [];
  static ROLES_RESERVATIONS = [];
  static ROLES_BOOKINGS = [];

  /************************************************************/
  /*********************** MAINTENANCE ***********************/
  /**********************************************************/

  static ROLES_REPAIRS = [RolesType.ADMIN_REPAIRS];
  static ROLES_ISSUE = [];
  static ROLES_REDISTRIBUTION = [];
  static ROLE_MAINTENANCE_PLAN = [];

  /******************************************************/
  /*********************** ACCOUNTING ******************/
  /****************************************************/

  static ROLES_PAYMENTS = [];
  static ROLES_INVOICES = [];
  static ROLES_CREDIT_CARDS = [];
  static ROLES_BOOKKEEPING = [];

  /**************************************************/
  /*********************** SEARCH ******************/
  /************************************************/

  static ROLES_SEARCH = [];

  /******************************************************/
  /*********************** CONFIGURATION ***************/
  /****************************************************/

  static ROLES_CONF_ORGANIZATIONS_TEAMS = [];
  static ROLES_CONF_ORGANIZATIONS_USERS = [];

  static ROLES_CONF_ASSETS_VEHICLE_MODELS = [];
  static ROLES_CONF_ASSETS_IOT_MODULE_TYPES = [];
  static ROLES_CONF_ASSETS_BATTERY_TYPES = [];

  static ROLES_CONF_SYSTEM_LANGUAGES = [];
  static ROLES_CONF_SYSTEM_BRANDING_COLORS = [];
  static ROLES_CONF_SYSTEM_BRANDING_LOGOS = [];
  static ROLES_CONF_SYSTEM_TEMPLATES = [];
  static ROLES_CONF_SYSTEM_API_KEYS = [];

  static ROLES_CONF_PAYMENTS_PAYMENT_GATEWAYS = [];
  static ROLES_CONF_PAYMENTS_PRODUCTS = [];

  static ROLES_CONF_SERVICE_ISSUE_CATEGORY = [];
}
