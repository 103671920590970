import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldBase } from '@shared/models/forms/field-base.model';

@Injectable()
export class FormControlService {
  constructor() {}

  toFormGroupInput(inputs: FieldBase<string>[]): FormGroup {
    const group: any = {};

    inputs.forEach((input) => {
      group[input.key] = input.required
        ? new FormControl(
            {
              value: input.value,
              disabled: input.disabled,
            },
            Validators.required,
          )
        : new FormControl({
            value: input.value,
            disabled: input.disabled,
          });

      if (input.group.length > 0) {
        group[input.key] = this.toFormGroupInput(input.group);
      }
    });

    return new FormGroup(group);
  }
}
