import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfigService } from '@core/services/config.service';
import { FilterBase } from '@shared/interfaces/filter-base.interface';
import { FiltersService } from '@shared/services/filters.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'va-filter-dialog',
  template: `
    <p-dialog
      [(visible)]="show"
      appendTo="body"
      [style]="{ width: '550px', height: 'auto', overflow: 'auto' }"
      [modal]="true"
      [draggable]="false"
      [closable]="false"
      >
      <ng-template pTemplate="header">
        <h3>{{ 'COMMONS.FILTERS.TITLE' | translate }}</h3>
        @if (enableFilterAdvanced) {
          <div class="advanced-filters">
            <p-checkbox
              [label]="'COMMONS.FILTERS.ADVANCED' | translate"
              [ngModel]="filtersAdvanced"
              (ngModelChange)="onAdvancedChange($event)"
              binary="true"
              inputId="binary"
              >
            </p-checkbox>
          </div>
        }
      </ng-template>
      <form #form="ngForm">
        <div class="grid">
          @if (filters.length > 0) {
            @for (filter of filters; track filter) {
              <div class="{{ filter.class }}" [class.display-none]="filter['hidden']">
                <va-filter-input [ngModelGroup]="filter.key" [filter]="filter"></va-filter-input>
              </div>
            }
          }
        </div>
      </form>
      <ng-template pTemplate="footer">
        <div class="float: left">
          <va-button
            [class]="'va-button-ghost'"
            [label]="'COMMONS.FILTERS.CLOSE' | translate"
            (clickButton)="closeFilters()"
          ></va-button>
        </div>
        <div class="float: right">
          <va-button
            [class]="'va-button-ghost'"
            [label]="'COMMONS.FILTERS.APPLY' | translate"
            [disabled]="!checkFormIsTouchedDirty(form) || checkFormIsEmpty(form)"
            (clickButton)="createFilters(form)"
          ></va-button>
        </div>
      </ng-template>
    </p-dialog>
    `,
})
export class FilterDialogComponent implements OnInit, OnDestroy {
  @Input() show = false;
  @Input() filters: FilterBase[] = [];
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filtersResult: EventEmitter<any> = new EventEmitter<any>();

  subscription: Subscription;
  filtersAdvanced = false;
  enableFilterAdvanced = false;

  constructor(private filtersService: FiltersService, private configService: ConfigService) {
    this.subscription = this.configService.getConfig().subscribe((config) => {
      if (config.filterAdvanced) {
        this.getAdvancedFilter();
      }

      this.enableFilterAdvanced = config.filterAdvanced;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {}

  onAdvancedChange(event: boolean): void {
    this.filtersService.setAdvancedFilter(event);
  }

  createFilters(form: NgForm): void {
    this.show = false;
    this.showChange.emit(this.show);
    this.filtersResult.emit(form.value);
  }

  closeFilters(): void {
    this.show = false;
    this.showChange.emit(this.show);
  }

  checkFormIsTouchedDirty(form: NgForm): boolean {
    let count = 0;

    Object.keys(form.controls).forEach((key) => {
      if (!form.controls[key].pristine && form.controls[key].dirty) {
        count++;
      }
    });

    return count > 0;
  }

  checkFormIsEmpty(form: NgForm): boolean {
    let count = 0;

    Object.keys(form.controls).forEach((key) => {
      if (!form.controls[key].value.value || form.controls[key].value.value.length === 0) {
        count++;
      }
    });

    return count === Object.keys(form.controls).length;
  }

  private getAdvancedFilter(): void {
    this.subscription = this.filtersService.getAdvancedFilter().subscribe((res) => {
      this.filtersAdvanced = res;
    });
  }
}
