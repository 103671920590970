import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class HeaderService {
  title = new BehaviorSubject('Initial Title');

  setTitle(title: string): void {
    this.title.next(title);
  }
}
