import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private state$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private stateMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  getState(): Observable<boolean> {
    return this.state$.asObservable();
  }

  setState(showHide: boolean): void {
    this.state$.next(showHide);
  }

  toggleSidebar(): void {
    this.state$.next(!this.state$.value);
  }

  getStateMobile(): Observable<boolean> {
    return this.stateMobile$.asObservable();
  }

  setStateMobile(showHide: boolean): void {
    this.stateMobile$.next(showHide);
  }

  toggleSidebarMobile(): void {
    this.stateMobile$.next(!this.stateMobile$.value);
  }
}
