import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
  name: 'vehiclePowerPipe',
})
export class VehiclePowerPipe implements PipeTransform {
  constructor(private translateService: TranslateService, private optionalValuePipe: OptionalValuePipe) {}

  transform(value: boolean | string): string {
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    return value === true || value === 'true'
      ? this.translateService.instant('COMMONS.VEHICLE_STATUS.ON')
      : value === false || value === 'false'
      ? this.translateService.instant('COMMONS.VEHICLE_STATUS.OFF')
      : this.optionalValuePipe.transform(value);
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
